<template>
    <div>
        <v-snackbar  v-model="snackbar2" :timeout="timeout" absolute top :color="colorfornack"
            outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon :color="colorfornack" text v-bind="attrs" @click="snackbar2 = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute top color="success"
            outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute bottom color="success" outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar1" :timeout="timeout" absolute top color="red"
            outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar1" :timeout="timeout" absolute bottom color="red" outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-container class="body-card">
            <v-card width="" height="" class="card-sty1 ">
                <v-progress-linear absolute color='#0037B7' height="12" value="24"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <v-col cols="3" class="d-none d-lg-block">

                        </v-col>
                        <v-col class="stageselect" cols="3">
                            <!-- {{ stageis }} -->
                            <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                                item-disabled="disable" :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 "
                                label="Stage" hide-details append-icon="mdi-chevron-down"></v-select>

                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row class="px-8">
                    <v-col cols="6" class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0 elevation-0">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col lg="6" class="cols-sm-12 pa-0 pl-2 mt-1">

                        <v-card v-if="cardloader" fill-height style="background-color: rgba(255, 255, 255, 0.733);  "
                            height="100%" width="100%" class="rounded-0 elevation-0 loader">
                            <div class="" style="margin-top: 138px;">
                                <v-progress-circular class="blocktext" :size="70" color="primary"
                                    indeterminate></v-progress-circular>
                                <p class="blocktext text-center subtitle-1  font-weight-bold">Please be wait....</p>
                            </div>

                        </v-card>
                        <v-card width="100%" class="rounded-0 elevation-0 " style="height: 434px; overflow-y: scroll;">


                            <p class="title font-weight-bold mb-0 mt-2" style="">We'll begin with your PAN
                                First <span v-if="datamatchdis" class=" font-weight-bold mt-3 ml-auto txtfld" style="color:rgb(20, 0, 201)"><a
                                            style="font-size:16px;" @click="changepan()"> - change</a></span> </p>

                            <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">Insert Your PAN
                                and
                                Date of Birth as mentioned on
                                your PAN Card</p>

                            <!-- <v-form ref="bankform"> -->

                            <!-- <v-text-field dense outlined min-height="5" rounded solo label="Enter mobile number to begin"
                                prepend-inner-icon="mdi-cellphone" flat type="phonenumber" class="inputPrice mx-2 elevation-0"
                                v-model="mobile_num" required prefix="+91" counter="10" v-on:keyup="MobNumberValidation()" tabindex="1"
                                style="white-space: nowrap; width: 90%; " @input="$v.mobile_num.$touch()"
                                @blur="$v.mobile_num.$touch()">
                            </v-text-field> -->
                            <!-- mdi mdi-card-account-details -->
                            <!-- v-if="!panFetchDetail" -->
                            <div>
                                <!-- <v-text-field  hide-details class="changefield" style="width: 93%;"
                                prefix="     " prepend-inner-icon="mdi mdi-card-account-details" counter="10" color="black"
                                hide-spin-buttons dense readonly min-height="5" flat v-model="panInput" solo>

                                <template v-slot:append>
                                    <p class=" font-weight-bold mt-3 ml-auto txtfld" style="color:rgb(20, 0, 201)"><a
                                            style="font-size:12px;" @click="changepan()">change</a></p>
                                </template>

                            </v-text-field> -->
                                <v-text-field :disabled="datamatchdis" color="black" oninput="this.value = this.value.toUpperCase()" dense
                                    outlined min-height="5" rounded solo label="PAN Number" :rules="[rulesfornumber]"
                                    prepend-inner-icon=" mdi mdi-card-account-details" flat
                                    class="lable  txtfld elevation-0" v-model="panInput" required counter="10"
                                    v-on:keyup="panNumberValidation()" style="white-space: nowrap; width: 93%;"
                                    prefix="       ">
                                    <template  v-slot:append>
                                    
                                </template>
                                </v-text-field>
                                <v-text-field :disabled="datamatchdis" color="black" oninput="this.value = this.value.toUpperCase()" dense
                                    outlined min-height="5" rounded solo label="PAN Name"
                                    prepend-inner-icon=" mdi mdi-account" flat class="lable  txtfld elevation-0"
                                    v-model="panInputname" required style="white-space: nowrap; width: 93%;" prefix=" ">
                                    <template  v-slot:append>
                                    
                                </template>
                                </v-text-field>
                                <v-text-field v-if="dobshow" :disabled="datamatchdis" color="black" oninput="this.value = this.value.toUpperCase()" dense
                                    outlined min-height="5" rounded solo label="DD/MM/YYYY (Pan DOB)"
                                    prepend-inner-icon=" mdi mdi-calendar-range" flat class="lable  txtfld elevation-0"
                                    v-model="panInputdob" required style="white-space: nowrap; width: 93%;" prefix=" ">
                                    
                                </v-text-field>
                                <!-- <v-text-field color="black" oninput="this.value = this.value.toUpperCase()" dense
                                    outlined min-height="5" rounded solo label="PAN Number"
                                    prepend-inner-icon="mdi mdi-phone" flat class="lable  txtfld elevation-0"
                                    v-model="panInputphno" required style="white-space: nowrap; width: 93%;" prefix=" ">
                                </v-text-field> -->
                                <p class="   mt- mb-2" style="font-size: 15px; color: #808080 !important;">Your Account
                                    Name
                                    will
                                    be taken as mentioned in
                                    the Income Tax Database</p>

                                <div class="" style="height: ">
                                    <v-btn v-if="!datamatchdis" :loading="btnloadforpancheck" @click="checkvaluecrtornot()"
                                        :disabled=" panInputdob == '' || panInputname == '' || panInput == ''"
                                        style="width: 93%;" color="black" elevation="2" small
                                        class="fltbtn py-6 otpbtn white--text mt-2 font-weight-bold body-2 mb-4"
                                        rounded>Check
                                    </v-btn>
                                    <v-btn v-else  @click="pancompleted()"
                                        
                                        style="width: 93%;" color="black" elevation="2" small
                                        class="fltbtn py-6 otpbtn white--text mt-2 font-weight-bold body-2 mb-4"
                                        rounded>Submit
                                    </v-btn>
                                    <!-- <v-btn v-if="panbtn" style="width: 93%;" color="black" elevation="2" small
                                    class="fltbtn py-6 otpbtn white--text mt-2 font-weight-bold body-2 mb-4" rounded
                                    @click="panFetch()">Fetch Pan
                                </v-btn> -->
                                </div>
                            </div>
                            <!-- <div  v-if="panFetchDetail">
                                <v-text-field  hide-details class="changefield" style="width: 93%;"
                                prefix="     " prepend-inner-icon="mdi mdi-card-account-details" counter="10" color="black"
                                hide-spin-buttons dense readonly min-height="5" flat v-model="panInput" solo>

                                <template v-slot:append>
                                    <p class=" font-weight-bold mt-3 ml-auto txtfld" style="color:rgb(20, 0, 201)"><a
                                            style="font-size:12px;" @click="changepan()">change</a></p>
                                </template>

                            </v-text-field>


                            <div class="d-flex justify-space-between mt-n6">
                                <div>
                                    <span class="caption font-weight-medium red--text  mr-10 mt-n3">{{ errorDescPAN
                                    }}</span>
                                </div>
                                <div>
                                    <span class="caption font-weight-medium red--text"></span>

                                </div>
                            </div>
                            <div class="d-flex justify-space-between mt-8 mb-0">
                                <div>
                                    <span class="caption font-weight-medium red--text"></span>
                                </div>
                                <div  style="width: 100%;">
                                    <v-text-field class="mr-10" color="black" label="Your Name" readonly v-model="name"
                                        prepend-inner-icon="mdi mdi-account"></v-text-field>
                                </div>
                                <div>
                                    <span class="caption font-weight-medium red--text mt-n3"></span>
                                </div>
                            </div>
                            <p class="   mt- mb-2" style="font-size: 15px; color: #808080 !important;">Your Account Name
                                will
                                be taken as mentioned in
                                the Income Tax Database</p>

                            
                            <v-btn  style="width: 93%;  " color="black" elevation="2" small
                                class="fltbtn py-6 otpbtn white--text mt-7 font-weight-bold body-2 mb-4" rounded
                                @click="aadharInit()">Confirm
                            </v-btn>
                            </div> -->
                            <!-- <v-btn color="primary" style="position:absolute;bottom:0; width:80%;"  elevation="2" small
class="py-4 my-4 ml-10 otpbtn"  @click="verifyEmail()" :loading="loading">
</v-btn> -->

                        </v-card>


                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import api from '@/api';
import imagePath from '../assets/inv1.svg'
import imagePath1 from '../assets/inv2.svg'
import imagePath2 from '../assets/inv3.svg'
import imagePath3 from '../assets/inv4.svg'
import imagePath4 from '../assets/inv5.svg'

export default {
    data: () => ({
        isLesserThanResolution: false,
        rulesfornumber: null,
        currentIndex: 0,
        images: [
            // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
            // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
            // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
            // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
            // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
            imagePath, imagePath1, imagePath2, imagePath3, imagePath4
            // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

            // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

            // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

        ],
        // Sign Up
        mobile_num: null,
        btnloadforpancheck: false,
        otp: '',
        stageis: 2,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],

        otpdisp: true,
        snackbar: false,
        // snackbar1: false,
        snackbar1: false,
        panInputphno: '',
        timerEnabled: false,
        timerCount: 120,
        resendText: false,
        toasttext: 'null',
        cardloader: false,
        errorOTPDisp: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        errorDescPAN: '',
        panbtn: false,
        // test
        mainurl: null,

        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        datamatchdis : false,
        retrybank: false,
        dobshow : true,
        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        panInputdob: '',
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: null,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: [],
        aadhaApi: '',
        panInputname: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        colorfornack : '',
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 4000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: null,
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        // errorDescPAN: '',
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: null,
        gender: null,
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,
        snackbar2: false,
        panchange: false,
    }),
    created() {
        this.startAutoChange();
        let stages = Number(localStorage.getItem("stageis"));
        if (typeof stages === 'number') {
            for (let s = 0; s < this.stagesitemsis.length; s++) {
                this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
            }
            if (stages < this.stageis) {
                this.stageis = stages;
                this.stageToo();
            }
        }
        else {
            this.$router.push("/");
        }
        const thresholdResolution = 767;

        const screenWidth = window.innerWidth;

        if (screenWidth > thresholdResolution) {
            this.isLesserThanResolution = true;
        } else {
            this.isLesserThanResolution = false;

        }
    },
    methods: {
        checkvaluecrtornot() {
            this.btnloadforpancheck = true
            const axios = require('axios');
            let data = JSON.stringify({
                "pan_no": this.panInput,
                "pan_name": this.panInputname,
                "pan_dob": this.panInputdob,
                "mobile": atob(localStorage.getItem('hash'))
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://ekycbe.mynt.in/pan/VerifyPanDetails',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            let axiosThis = this
            axios.request(config)
                .then((response) => {
                    // console.log(response.data.outputData[0]);
                    if (response.data.response_Code == 'Success') {
                        if (response.data.outputData[0].pan_status == 'E') {
                            if (response.data.outputData[0].name == 'N' && response.data.outputData[0].dob == 'N') {
                                axiosThis.snackbar2 = true
                                axiosThis.toasttext = "Name and Dob does not match"
                                axiosThis.colorfornack = 'red accent-2'
                                axiosThis.success = false
                                axiosThis.failure = true
                                axiosThis.panInputname = ''
                                axiosThis.panInputdob = ''
                                axiosThis.btnloadforpancheck = false

                            }
                            if (response.data.outputData[0].dob == 'N' && response.data.outputData[0].name == 'Y') {
                                axiosThis.snackbar2 = true
                                axiosThis.toasttext = "DOB does not match"
                                axiosThis.success = false
                                axiosThis.colorfornack = 'red accent-2'
                                axiosThis.failure = true
                                axiosThis.btnloadforpancheck = false
                                axiosThis.panInputdob = ''
                            }
                            if (response.data.outputData[0].dob == 'Y' && response.data.outputData[0].name == 'N') {
                                axiosThis.snackbar2 = true
                                axiosThis.toasttext = "Name does not match"
                                axiosThis.success = false
                                axiosThis.colorfornack = 'red accent-2'
                                axiosThis.failure = true
                                axiosThis.btnloadforpancheck = false
                                axiosThis.panInputname = ''
                            }
                            if (response.data.outputData[0].name == 'Y' && response.data.outputData[0].dob == 'Y') {
                                axiosThis.snackbar2 = true
                                axiosThis.toasttext = "Data Matched"
                                axiosThis.success = true
                                axiosThis.btnloadforpancheck = false
                                axiosThis.colorfornack = 'success'
                                axiosThis.failure = false
                                axiosThis.datamatchdis = true
                            }
                        } else {
                            axiosThis.snackbar2 = true
                            axiosThis.toasttext = "Does not match with our record"
                            axiosThis.success = false
                            axiosThis.btnloadforpancheck = false
                                axiosThis.colorfornack = 'failure'
                            axiosThis.failure = true
                             
                        }
                    } else {
                        axiosThis.snackbar2 = true
                        axiosThis.toasttext = response.data.response_Code
                        axiosThis.success = false
                        axiosThis.failure = true
                        
                                axiosThis.btnloadforpancheck = false
                       
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        startAutoChange() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.images.length;
            }, 5000); //   Change every 5 seconds
        },
        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },

        // pan validation
        panNumberValidation() {
            this.errorDescPAN = ''
            const regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            if (this.panInput) {
                // if (this.panInput.length == 10 || this.panInput.length == 11) {
                //     this.panInputLength = false;
                // }
                // else {
                //     this.panInputLength = true;
                // }
                var newVal = this.panInput.replace('-', '');
                ////console.log("New Val:", newVal);
                if (this.panInput.length >= 10) {
                    if (regex.test(this.panInput)) {
                        this.panbtn = true;
                    } else {
                        this.rulesfornumber = 'Invalid Pan Number'
                        // this.panInput = null
                        setTimeout(() => {
                            this.rulesfornumber = '';

                        }, 2000);
                        setTimeout(() => {
                            // this.panInput = '';

                        }, 500);
                    }

                    this.panInput = newVal.slice(0, 10);
                } else {
                    this.panbtn = false;
                    this.rulesfornumber = null;
                }

            }
        },

        // pan fetch
        panFetch() {
            // this.$v.$touch()
            //console.log("panchange", this.panchange)

            // //console.log("log");
            if (this.panchange == true) {
                if (this.panInput) {
                    // this.cardloader = true
                    // this.$v.$reset()
                    //console.log("normal apiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
                    var plan = localStorage.getItem('nalp')
                    // //console.log("don",plan)

                    var axiosThis = this

                    var config = {
                        method: 'post',
                        url: api.api_url + '/panCheck?PAN=' + axiosThis.panInput + '&nalp=' + plan + '&mob=' + atob(localStorage.getItem('hash')) + '&change=' + 'change'+ '&name=' + this.panInputname +  '&dob=' + this.panInputdob,
                        headers: {}
                    };
                    axios(config)
                        .then(function (response) {
                            // alert("1")
                            //console.log(JSON.stringify(response.data));
                            var responsePANfetch = response.data
                            if (responsePANfetch["Name"] == 'No Data') {
                                axiosThis.cardloader = false
                                ////console.log("Not verified")
                                axiosThis.errorDescPAN = 'Entered Pan is invalid',
                                    axiosThis.cardloader = false;
                                setTimeout(() => {
                                    axiosThis.errorDescPAN = '';
                                }, 3000);
                                axiosThis.panInput = null
                            } else if (responsePANfetch["stat"] == 'Error') {
                                axiosThis.cardloader = false

                                axiosThis.rulesfornumber = 'Wrong Pan Number'
                                // this.panInput = null
                                setTimeout(() => {
                                    axiosThis.rulesfornumber = '';

                                }, 4000);
                            }
                            else {
                                //console.log("PAN Verified")
                                axiosThis.cardloader = false
                                axiosThis.name = responsePANfetch["Name"]
                                //console.log("PAN Verified", axiosThis.name)

                                // var pannumber = sessionStorage.getItem('panInput')
                                // axiosThis.panInput = pannumber
                                // var name = sessionStorage.getItem('name')
                                // axiosThis.name = name
                                //axiosThis.panDetaildisp = false
                                axiosThis.panFetchDetail = true
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            axiosThis.cardloader = false

                            axiosThis.snackbar2 = true
                            axiosThis.toasttext = "Something went wrong please try again later"
                            axiosThis.success = false
                            axiosThis.failure = true
                            // axiosThis.panInput = null
                        });

                }
                this.panchange = false
            }
            else if (this.panInput) {
                this.cardloader = true
                // this.$v.$reset()
                //console.log("normal api")
                const plan = localStorage.getItem('nalp')

                var config1 = {
                    method: 'post',
                    url: api.api_url + '/panCheck?PAN=' + this.panInput + '&nalp=' + plan + '&mob=' + atob(localStorage.getItem('hash')) + '&change=' + 'change'+ '&name=' + this.panInputname +  '&dob=' + this.panInputdob,
                    headers: {}
                };
                const axiosThis = this
                axios(config1)
                    .then(function (response) {
                        // alert("2")

                        // console.log(JSON.stringify(response.data));
                        var responsePANfetch = response.data
                        //console.log("lolo", responsePANfetch.stat);
                        if (responsePANfetch["Name"] == 'No Data') {
                            axiosThis.cardloader = false
                            ////console.log("Not verified")
                            axiosThis.errorDescPAN = 'Entered Pan is invalid',
                                axiosThis.cardloader = false;
                            setTimeout(() => {
                                axiosThis.errorDescPAN = '';
                            }, 3000);
                            axiosThis.panInput = null
                        } else if (responsePANfetch["stat"] == 'Error') {
                            axiosThis.cardloader = false

                            axiosThis.rulesfornumber = 'Wrong Pan Number'
                            // this.panInput = null
                            setTimeout(() => {
                                axiosThis.rulesfornumber = '';

                            }, 2000);
                        }
                        else {
                            //console.log("PAN Verified")
                            axiosThis.cardloader = false
                            axiosThis.name = responsePANfetch["Name"]
                            //console.log("PAN Verified", axiosThis.name)

                            // var pannumber = sessionStorage.getItem('panInput')
                            // axiosThis.panInput = pannumber
                            // var name = sessionStorage.getItem('name')
                            // axiosThis.name = name
                            //axiosThis.panDetaildisp = false
                            axiosThis.panFetchDetail = true
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        axiosThis.cardloader = false

                        axiosThis.snackbar2 = true
                        axiosThis.toasttext = "Something went wrong please try again later"
                        axiosThis.success = false
                        axiosThis.failure = true
                        axiosThis.panInput = null
                    });

            }

        },

        // afterpan
        aadharInit() {
            // alert("out")
            if (this.aadharNo) {
                this.kycLoader = false
                this.KycFetchDisp = true
                this.$router.push("/aadhar");
                localStorage.setItem("stageis", 3)
                // alert("if")
                //   this.Slidervalue = 36
            }
            else {
                this.$router.push("/aadhar");
                localStorage.setItem("stageis", 3)
                // alert("else")

                //   this.Slidervalue = 36
            }
        },
        pancompleted(){
            // if (this.panInput == null) {
            // this.cardloader = true
            // this.$v.$reset()
               
                    var plan = localStorage.getItem('nalp')
                    var config = {
                method: 'post',
                url: api.api_url + '/panCheck?PAN=' + this.panInput + '&nalp=' + plan + '&mob=' + atob(localStorage.getItem('hash')) + '&name=' + this.panInputname +  '&dob=' + this.panInputdob,
                headers: {}
                    };
                    var axiosThis = this
                    axios(config)
                .then(function (response) {
                    // alert("3")

                    // console.log(JSON.stringify(response.data));
                    var responsePANfetch = response.data
                    if (responsePANfetch["Name"] != null) {
                        //console.log("PAN Verified")
                        axiosThis.cardloader = false
                        axiosThis.datamatchdis = true
                        axiosThis.panInput = responsePANfetch["PAN"]
                        axiosThis.panInputname = responsePANfetch["Name"]
                        axiosThis.panInputdob = responsePANfetch["pan_dob"]
                        //console.log("PAN Verified", axiosThis.name)
                        // console.log("PAN Verified", axiosThis.panInputdob)
                        if (axiosThis.panInputdob) {
                            axiosThis.dobshow = true
                        }else{
                            axiosThis.dobshow = false
                        }
                        if (axiosThis.panInput && axiosThis.panInputname && axiosThis.panInputdob) {
                            // alert("ghjkl")
                            axiosThis.aadharInit()
                                // this.$router.push("/aadhar");
                                // localStorage.setItem("stageis", 3)

                                //   this.Slidervalue = 36
                        }
                        // var pannumber = sessionStorage.getItem('panInput')
                        // axiosThis.panInput = pannumber
                        // var name = sessionStorage.getItem('name')
                        // axiosThis.name = name
                        //axiosThis.panDetaildisp = false
                        axiosThis.panFetchDetail = true
                    }
                    else if (responsePANfetch["Name"] == 'No Data') {
                        // axiosThis.cardloader = false
                        ////console.log("Not verified")
                        axiosThis.errorDescPAN = 'Entered Pan is invalid',
                            axiosThis.cardloader = false;
                        setTimeout(() => {
                            axiosThis.errorDescPAN = '';
                        }, 3000);
                        axiosThis.panInput = null
                    } else if (responsePANfetch["stat"] == 'Error') {
                        axiosThis.panFetchDetail = false
                        axiosThis.panInput = null
                        axiosThis.panbtn = false
                        axiosThis.panchange = true
                    }
                    
                })
                .catch(function (error) {
                    console.log(error);
                    // axiosThis.cardloader = false

                    // axiosThis.snackbar2 = true
                    // axiosThis.toasttext = "Something went wrong please try again later"
                    // axiosThis.success = false
                    // axiosThis.failure = true
                    // axiosThis.panInput = null
                });
                
            

        // }
        },
        changepan() {
            this.dobshow = true
            this.panInput = ''
            this.panInputphno = ''
            this.panInputdob = ''
            this.panInputname = ''
            this.datamatchdis = false
            this.panFetchDetail = false
            this.panInput = null
            this.panbtn = false
            this.panchange = true
            //console.log("panchange", this.panchange)
        },
        // handleKeyUp(event) {

        //     if (this.panFetchDetail == false) {
        //         // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
        //         if (event.key === 'Enter') {
        //             // alert("fghj")
        //             this.panFetch();
        //             ////console.log("verify mobile Enter pressed");

        //             // this.otpVerify();
        //             // this.verifyEmail();
        //         }

        //         // }
        //     } else if (this.panFetchDetail == true) {
        //         if (event.key === 'Enter') {
        //             // alert("fghj")
        //             this.aadharInit();
        //             ////console.log("verify mobile Enter pressed");

        //             // this.otpVerify();
        //             // this.verifyEmail();
        //         }
        //     }
        // },

    },

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    watch: {
        loader() {
            if (this.mobile_num) {
                if (this.mobile_num.length == 10) {
                    //this.errorMobNumber=false
                    const l = this.loader
                    this[l] = !this[l]

                    setTimeout(() => (this[l] = false), 2000)

                    this.loader = null
                    if (this.emailDisp != true) {
                        this.loadertext = "OTP Sent"
                        // this.$v.$reset();
                        setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 3000)
                    }
                }
            }

        },
        panLoader() {
            const l = this.panLoader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 3000)

            this.panLoader = null
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 3000);
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 3000);
                }
                if (value == 0) {
                    this.timerEnabled = false
                    this.resendText = true
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        },


    },
    computed: {

    },

    mounted() {
        document.addEventListener('keyup', this.handleKeyUp);
        // this.cardloader = true

        // this.stageis = localStorage.getItem("stageis")
        var mob = localStorage.getItem('hash')
        // console.log(mob);
        
        if (mob == null) {
            this.$router.push("/");

        }
        if (this.panInput == null) {
            // this.cardloader = true
            // this.$v.$reset()

            var plan = localStorage.getItem('nalp')
            var config = {
                method: 'post',
                // + '&name=' + this.panInputname +  '&dob=' + this.panInputdob
                url: api.api_url + '/panCheck?PAN=' + this.panInput + '&nalp=' + plan + '&mob=' + atob(localStorage.getItem('hash')) ,
                headers: {}
            };
            var axiosThis = this
            axios(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    // alert("4")

                    var responsePANfetch = response.data
                    if (responsePANfetch["Name"] == 'No Data') {
                        // axiosThis.cardloader = false
                        ////console.log("Not verified")
                        axiosThis.errorDescPAN = 'Entered Pan is invalid',
                            axiosThis.cardloader = false;
                        setTimeout(() => {
                            axiosThis.errorDescPAN = '';
                        }, 3000);
                        axiosThis.panInput = null
                    } else if (responsePANfetch["stat"] == 'Error') {
                        axiosThis.panFetchDetail = false
                        axiosThis.panInput = null
                        axiosThis.panbtn = false
                        axiosThis.panchange = true
                    }
                    else if (responsePANfetch["Name"] != null) {
                        //console.log("PAN Verified")
                        axiosThis.cardloader = false
                        axiosThis.datamatchdis = true
                        axiosThis.panInput = responsePANfetch["PAN"]
                        axiosThis.panInputname = responsePANfetch["Name"]
                        axiosThis.panInputdob = responsePANfetch["pan_dob"]

                        // console.log("PAN Verified", axiosThis.panInputdob)
                        if (axiosThis.panInputdob) {
                            axiosThis.dobshow = true
                        }else{
                            axiosThis.dobshow = false
                        }
                       
                        // var pannumber = sessionStorage.getItem('panInput')
                        // axiosThis.panInput = pannumber
                        // var name = sessionStorage.getItem('name')
                        // axiosThis.name = name
                        //axiosThis.panDetaildisp = false
                        axiosThis.panFetchDetail = true
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    // axiosThis.cardloader = false

                    // axiosThis.snackbar2 = true
                    // axiosThis.toasttext = "Something went wrong please try again later"
                    // axiosThis.success = false
                    // axiosThis.failure = true
                    // axiosThis.panInput = null
                });

        }

        if (sessionStorage.getItem('panInput')) {
            var pannumber = sessionStorage.getItem('panInput')
            //console.log("321");
            this.panInput = pannumber
            // axiosThis.name = responsePANfetch["Name"]
            var name = sessionStorage.getItem('name')
            this.name = name
            this.panFetchDetail = true
            // this.snackbar = true
            // this.toasttext = 'OTP Verified'
        } else if (sessionStorage.getItem('snackbar')) {
            this.snackbar = false

        } else {
            // this.snackbar = true
            // this.toasttext = 'Mail OTP Verified'
            // this.panFetchDetail = false
        }
    }

}

</script>
<style lang="scss">
/* .card-sty1 {
    border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 1px 2px 1px rgba(116, 116, 116, 0.20) !important;
}

.body-card {
    height: 90vh !important;
    overflow-y: scroll !important;
} */
.card-sty1 {
    /* border: 1px solid #F2F2F2 !important; */
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden !important;
    border-radius: 30px !important;
    /* margin-left: -1px !important; */
    /* margin-right: 2px !important; */
}

.body-card {
    height: 100vh !important;


    /* overflow-y: scroll !important; */
}

// ::-webkit-scrollbar {
//     display: none;
// }

.loader {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;

}

.blocktext {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.changefield {
    border: 2px solid rgb(177, 177, 177);
    border-style: dashed;

    border-radius: 20px;
}
</style>