<template>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="inputValue"
                label="Enter a value"
                :error-messages="validationErrors"
                @input="validateInput"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn @click="submitForm">Submit</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    data() {
      return {
        inputValue: '', // Data property to store the input value
        validationErrors: [] // Array to store validation error messages
      };
    },
    methods: {
      validateInput() {
        // Your validation logic here
        this.validationErrors = [];
  
        if (this.inputValue.length === 0) {
          this.validationErrors.push('Value is required.');
        }
  
        // You can add more validation rules as needed
  
      },
      submitForm() {
        this.validateInput();
  
        // Check if there are validation errors
        if (this.validationErrors.length === 0) {
          // Perform further actions here, e.g., submit the form
          console.log('Form submitted with value:', this.inputValue);
        } else {
          // Display validation errors
          console.log('Form has validation errors:', this.validationErrors);
        }
      }
    }
  };
  </script>
  