<template>
  <div>
    <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute top color="success" outlined right class="">
      <!-- <v-card> -->
      <v-icon color="green" v-if="success">
        "mdi mdi-check-circle
      </v-icon>
      <v-icon color="red" v-if="failure">
        mdi mdi-close-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- </v-card> -->
    </v-snackbar>
    <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute bottom color="success" outlined  class="">
      <!-- <v-card> -->
      <v-icon color="green" v-if="success">
        "mdi mdi-check-circle
      </v-icon>
      <v-icon color="red" v-if="failure">
        mdi mdi-close-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- </v-card> -->
    </v-snackbar>
    <v-snackbar  v-if="isLesserThanResolution" v-model="snackbar1" :timeout="timeout" absolute top color="red" outlined right class="">
      <!-- <v-card> -->
      <v-icon color="green" v-if="success">
        "mdi mdi-check-circle
      </v-icon>
      <v-icon color="red" v-if="failure">
        mdi mdi-close-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- </v-card> -->
    </v-snackbar>
    <v-snackbar  v-else v-model="snackbar1" :timeout="timeout" absolute bottom color="red" outlined  class="">
      <!-- <v-card> -->
      <v-icon color="green" v-if="success">
        "mdi mdi-check-circle
      </v-icon>
      <v-icon color="red" v-if="failure">
        mdi mdi-close-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- </v-card> -->
    </v-snackbar>
    <v-container class="body-card">
      <v-card width="" height="" class="card-sty1  ">
        <v-progress-linear absolute color='#0037B7' height="12" value="12"></v-progress-linear>
        <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
          <v-row no-gutters>
            <v-col cols="6">
              <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

            </v-col>
            <v-col cols="3" class="d-none d-lg-block">

            </v-col>
            <v-col class="stageselect" cols="3">
              <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                :items="stagesitemsis" item-disabled="disable" v-model="stageis" flat solo background-color="#ffffff"
                class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 " label="Stage" hide-details
                append-icon="mdi-chevron-down"></v-select>

            </v-col>
          </v-row>
        </v-toolbar>
        <v-row class="px-8">
          <v-col cols="6" class="d-none d-lg-block">
            <v-card width="100%" class="rounded-0 elevation-0 ">
              <img v-for="(image, index) in images" :key="index" :src="image"
                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />

            </v-card>
          </v-col>
          <v-col class="pa-0 pa-1 pl-2 cols-sm-12">
            <v-card v-if="!otpdisplay" width="100%" class="rounded-0 elevation-0">
              <v-card v-if="cardloader" fill-height style="background-color: rgba(255, 255, 255, 0.733);  " height="504"
                width="" class="rounded-0 elevation-0 loader">
                <div class="" style="margin-top: 138px;">
                  <v-progress-circular class="blocktext" :size="70" color="primary" indeterminate></v-progress-circular>
                  <p class="blocktext text-center subtitle-1  font-weight-bold">Please be wait....</p>
                </div>

              </v-card>
              <p class="title font-weight-bold mb-0 mt-2" style="font-family: 'Tenon' !important;">
                Verify Your Email</p>

              <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">An email address to ensure it's
                real and active.</p>

              <!-- <p class="body-1 font-weight-light mt-1">Create your Partner account in minutes</p> -->
              <!-- <v-form ref="bankform"> -->

              <!-- <v-text-field dense outlined min-height="5" rounded solo label="Enter mobile number to begin"
                  prepend-inner-icon="mdi-cellphone" flat type="phonenumber" class="inputPriceOTP mx-2 elevation-0"
                  v-model="mobile_num" required prefix="+91" counter="10" v-on:keyup="MobNumberValidation()" tabindex="1"
                  style="white-space: nowrap; width: 90%; " @input="$v.mobile_num.$touch()"
                  @blur="$v.mobile_num.$touch()">
                </v-text-field> -->
              <v-text-field color="black" hide-spin-buttons dense outlined min-height="5" rounded solo
                :rules="[rulesfornumber]" label="Email ID" prepend-inner-icon="mdi mdi-gmail" flat
                class="lable  elevation-0" v-model="email" required prefix="       " v-on:keyup="emailvalidation()"
                tabindex="1" style="white-space: nowrap; width: 93%; " @input="$v.email.$touch()"
                @blur="$v.email.$touch()">
              </v-text-field>




              <div class="d-flex justify-space-between mt-n6" style="position: fixed;">
                <div>
                  <!-- <span class="caption font-weight-medium red--text ml-5 mt-n3">{{ emailtext }}</span> -->

                </div>
                <div>
                  <span class="caption font-weight-medium red--text"></span>
                </div>
              </div>
              <!-- <input  type="checkbox" id="vehicle1" name="vehicle1" value="Bike"> -->
              <!-- </v-form> -->
              <!-- <v-checkbox class="custom-checkbox  mt-n1" v-model="checkbox"
                label="I declare that the given email ID is mine" required style="font-size:12px;">
              </v-checkbox> -->

              <p class=" mt-2" style="font-size: 15px; color: #808080 !important;">By submitting your contact details,
                you authorise Zebu to call
                or text you for the purpose of account opening even though you may be registered on DND
              </p>

              <v-btn v-if="!checkbox" style="width: 93%;" color="black" disabled elevation="2" small
                class="fltbtn py-6 otpbtn font-weight-bold body-2 white--text mt-9 mb-4" rounded @click="verifyEmail()">Verify Email
              </v-btn>
              <v-btn v-if="checkbox" style="width: 93%;" color="black" elevation="2" small
                class="fltbtn font-weight-bold body-2 py-6 otpbtn white--text mt-9 mb-4" rounded @click="verifyEmail()">Verify Email
              </v-btn>

              <!-- <v-btn color="primary" style="position:absolute;bottom:0; width:80%;"  elevation="2" small
            class="py-4 my-4 ml-10 otpbtn"  @click="verifyEmail()" :loading="loading">
          </v-btn> -->
            </v-card>
            <v-card v-else width="100%" class="rounded-0 elevation-0">
              <p class="title font-weight-bold mt-2 mb-0">Verify Your Mobile</p>
              <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">An extra layer of protection by
                providing your phone number.</p>
              <v-text-field hide-details class="changefield" style="width: 93%;" prepend-inner-icon="mdi mdi-gmail"
                color="black" hide-spin-buttons dense readonly min-height="5" flat v-model="email" solo>

                <template v-slot:append>
                  <p class=" font-weight-bold mt-3 " style="color:rgb(20, 0, 201)"><a style="font-size:12px;"
                      @click="changeEmailId()">change</a></p>
                </template>

              </v-text-field>

              <v-otp-input outlined dense  color="black" length="4" type="number" class="inputPriceOTP mt-9 mr-7" v-model="otpemail"
                @finish="otpVerifyemail()" @input="$v.otp.$touch()" @blur="$v.otp.$touch()" counter="4"
                required></v-otp-input>
              <div class="d-flex justify-space-between mt-n3 px- pr-5" style="margin-bottom: px; position: fixed;">
                <div>
                  <span class="caption font-weight-medium red--text ">{{ mailotptext }}</span>

                </div>
                <div>

                </div>
              </div>





              <p class=" mt-7  mr-3" style="font-size: 15px; color: #808080 !important;">By submitting your contact
                details,
                you authorise Zebu to call
                or text you for the purpose of account opening even though you may be registered on DND
              </p>


              <v-btn @click="emailOtpResend()" :disabled="!resendText" color="black" style="width: 93%;" rounded
                class="fltbtn mb-4 py-6 white--text font-weight-bold body-2 mt-2 ml-">
                <span class="text-center " v-if="timerEnabled">Resend OTP in
                  {{ timerCount }}
                </span>
                <span class="text-center " v-if="resendText" style="">Don't receive OTP? resend</span>
              </v-btn>


              <!-- <v-btn v-if="!eotpdisplay" style="width: 93%;" color="black" disabled elevation="2" small
                class=" py-6 otpbtn white--text mt-9 font-weight-bold body-2" rounded @click="otpVerifyemail()">Verify
                Email
              </v-btn>
              <v-btn v-if="eotpdisplay" style="width: 93%;" color="black" elevation="2" small
                class=" py-6 otpbtn white--text mt-9 font-weight-bold body-2" rounded @click="otpVerifyemail()">Verify
                Email
              </v-btn> -->

              <!-- <v-btn color="primary" style="position:absolute;bottom:0; width:80%;"  elevation="2" small
class="py-4 my-4 ml-10 otpbtn"  @click="verifyEmail()" :loading="loading">
</v-btn> -->
            </v-card>
            <!-- <p  style="color:grey;padding-top:20px;padding-left:10px;">Code sent to <span
                style="color:black;">{{ email }} </span> <a style="font-size:14px;" @click="changeEmailId()">(change)</a>
            </p> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import api from '@/api';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'

export default {
  data: () => ({
    currentIndex: 0,
    images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],
    // Sign Up
    isLesserThanResolution : false,
    rulesfornumber: null,
    mobile_num: null,
    otp: '',
    stageis: 1,
    stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],

    otpdisp: true,
    snackbar: false,
    timerEnabled: false,
    timerCount: 120,
    resendText: false,
    toasttext: 'null',
    cardloader: false,
    errorOTPDisp: false,
    numberStart: true,
    otpstart: true,
    otptext: '',
    emailtext: '',
    checkbox: false,
    email: null,
    emailDisp: false,
    // emailOtpDisp: false,
    otpemail: null,
    otpdisplay: false,
    eotpdisplay: false,
    mailotptext: '',
    // test\
    mainurl: null,

    accountTypeItems: ['Saving Account', 'Current Account'],
    accountType: null,
    accNo: null,
    ifsc: null,
    retrybank: false,

    retrybankacc: false,
    retryCount: 0,
    fileattach1st: false,
    opencam: false,
    // cam
    isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    link: '#',
    photoData: null,
    localHash: null,
    dialog1: false,
    panStatus: null,
    pennyVerify: '',
    acctype: null,
    eduFile: false,
    DisplayData: [],
    aadhaApi: '',
    fatherNameval: '',
    corFather: '',
    bankApi: '',
    Slidervalue: 1,
    eduApi: '',
    // fatherNameval : '',
    // from kyc
    loader: null,
    panLoader: null,
    loading: false,
    // mobile_num: null,
    //errorMobNumber:false,
    logstatus: false,
    reference: null,
    // timerCount: 120,
    // timerEnabled: false,
    // resendText: false,

    // otp: null,
    invalid: null,
    text: `You will receive an OTP on your number`,
    loadertext: "Verify",
    timeout: 1000,
    // otpdisp: true,
    mobdisp: true,
    isShowing: false,
    activePicker: null,
    date: null,
    menu: false,
    panDetaildisp: true,
    panFetchDetail: false,
    panInput: null,
    // accNo:s null,
    // ifsc: null,
    bank_name: null,
    bank_branch: null,
    bank_micr: null,
    enabledRegisteredAP: false,
    regMemberName: null,
    exchangeSelect: [],
    tradingExperience: null,
    qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
    occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
    qualification: null,
    occupation: null,
    errorDescPAN: false,
    KycFetchDisp: false,
    dob: null,
    aadharNo: null,
    add1: null,
    add2: null,
    add3: null,
    add4: null,
    address: null,
    country: null,
    district: null,
    state: null,
    pinCode: null,
    fatherName: null,
    gender: null,
    name: null,
    panFile: null,
    // aadharFile:null,
    bankFile: null,
    educationFiler: null,
    kycLoader: false,
    checkboxEsign: false,
    digioFile: null,
    digioMail: null,
    ekycStatus: false,
    step: 1,
    bankverifystatus: null,

    digiInformationShow: false,
    dialog: false,
    isDarkModeEnabled: false,
    // errorOTPDisp: false,
    erroreOTPDisp: false,
    // cardloader: false,
    ifscErrorDesc: false,
    // snackbar: false,
    // toasttext: 'null',
    // timeout: 2000,
    panInputLength: false,
    success: false,
    failure: false,

  }),
  created() {
    this.startAutoChange();
    let stages = Number(localStorage.getItem("stageis"));
    if (typeof stages === 'number') {
      for (let s = 0; s < this.stagesitemsis.length; s++) {
        this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
      }
      if (stages < this.stageis) {
        this.stageis = stages;
        this.stageToo();
      }
    }
    else {
      this.$router.push("/");
    }
    const thresholdResolution = 767;

    const screenWidth = window.innerWidth;

    if (screenWidth < thresholdResolution) {
      this.isLesserThanResolution = true;
    }else{
      this.isLesserThanResolution = false;

    }
  },
  methods: {
    startAutoChange() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }, 5000); // Change every 5 seconds
    },
    stageToo() {
      if (this.stageis == 1) {
        this.$router.push("/");
      }
      else if (this.stageis == 2) {
        this.$router.push("/email");
      }
      else if (this.stageis == 3) {
        this.$router.push("/pan");
      }
      else if (this.stageis == 4) {
        this.$router.push("/aadhar");
      }
      else if (this.stageis == 5) {
        this.$router.push("/bank");
      }
      else if (this.stageis == 6) {
        this.$router.push("/segments");
      }
      else if (this.stageis == 7) {
        this.$router.push("/fileupload");
      }
      else if (this.stageis == 8) {
        this.$router.push("/preview");
      }
      else if (this.stageis == 9) {
        this.$router.push("/submit");
      }
      // else if (this.stageis == 8) {
      //     this.$router.push("");
      // }
    },

    // verify email
    verifyEmail() {

      // this.$v.$touch()
      // this.cardloader = true

      if (this.email.includes('@') && this.email.includes('.')) {

        this.cardloader = true;
        // this.$v.$reset()

        var hash = new Date().toLocaleString()
        localStorage.setItem('sesslog', btoa(hash))
        var config = {
          method: 'post',
          url: api.api_url + '/email?mob=' + this.mobile_num + '&email=' + this.email + '&hash=' + btoa(hash),
          headers: {}
        };
        var axiosThis = this
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            axiosThis.cardloader = false
            axiosThis.toasttext = "OTP sent to Email";
            axiosThis.emailtext = '',
              axiosThis.snackbar = true;
            axiosThis.success = true;
            axiosThis.failure = false;
            axiosThis.otpdisplay = true
            axiosThis.timerEnabled = true
            // this.$v.$reset()
          })
          .catch(function (error) {
            console.log(error);
          });

        this.otpdisp = true
        this.timerEnabled = false
        this.timerCount = 121
        // this.emailDisp = false
        // this.emailOtpDisp = true
        this.cardloader = false
      } else {
        this.rulesfornumber = "Enter mail Id"
        this.checkbox = false
      }


    },

    // change email
    changeEmailId() {
      this.otpdisplay = false
      this.checkbox = false
      this.email = null
    },

    // validate otp
    eOtpValidation() {
      if (this.otpemail) {
        // this.errorOTPDisp = false;
        // //console.log(this.otp)
        var newVal = this.otp.replace('-', '');
        ////console.log("New Val:", newVal);
        if (newVal.length >= 4) {
          this.otp = newVal.slice(0, 4);
          this.eotpdisplay = false

        } else {
          this.eotpdisplay = true

        }
      }
    },


    // verify email otp
    otpVerifyemail() {
      this.cardloader = true
      // this.$v.$touch()
      //console.log("tghjklkjhg");
      if (this.otpemail) {
        if (this.otpemail.length == 4) {

          // this.$v.$reset()
          var config = {
            method: 'post',
            url: api.api_url + '/otpVerifyEmail?mob=' + this.mobile_num + '&email=' + this.email + '&code=' + this.otpemail + '&hash=' + localStorage.getItem('sesslog'),
            headers: {}
          };
          var axiosThis = this
          axios(config)
            .then(function (response) {
              //console.log(JSON.stringify(response.data));
              var responseOTPemail = response.data
              if (responseOTPemail['status'] == 'Verified') {
                axiosThis.toasttext = "Email OTP is Verified";
                axiosThis.snackbar = true;
                axiosThis.success = true;
                axiosThis.failure = false;
                // axiosThis.erroreOTPDisp = false
                axiosThis.otpdisplay = false
                // axiosThis.step = 2
                axiosThis.cardloader = false

                axiosThis.$router.push('/pan')
                // localStorage.setItem("stageis",3)

                // axios.Slidervalue = 20

                // this.$v.$reset()
              }
              else {
                axiosThis.otpemail = null
                // axiosThis.erroreOTPDisp = true
                axiosThis.otpdisplay = true
                axiosThis.cardloader = false

                axiosThis.mailotptext = 'OTP is invalid'
                setTimeout(() => {
                  axiosThis.mailotptext = '';
                }, 3000); // 3000 milliseconds = 3 seconds

              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else {
          this.otpemail = null
          // this.erroreOTPDisp = true;
          // axiosThis.otpdisplay = true

          this.cardloader = false
        }
      }
      else {

        this.cardloader = false
      }
    },
    emailOtpResend() {
      // const axios = require('axios'  );
      var hash = new Date().toLocaleString()
      localStorage.setItem('sesslog', btoa(hash))
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: api.api_url + '/resendemailotp?mob=' + this.mobile_num + '&email=' + this.email + '&hash=' + btoa(hash),
        headers: {}
      };
      var axiosThis = this
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          //console.log("resend otp");
          axiosThis.timerEnabled = true
          axiosThis.resendText = false
          axiosThis.timerCount = 121
          axiosThis.toasttext = "OTP resent";
          axiosThis.snackbar = true;
          axiosThis.success = true;
          axiosThis.failure = false;

        })
        .catch((error) => {
          console.log(error);
        });

    },
   
    emailvalidation() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-][a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-][a-zA-Z0-9-]+)*$/;
      // var validRegex = /^(?:\.[a-zA-Z0-9-][a-zA-Z0-9-]+)*$/;
      // var validRegex = 
      if (this.email == null) {
        this.cardloader = false;
        // this.emailtext = 'Email is required';
        // this.success = false
        // this.failure = true
        // this.snackbar1 = true
        this.rulesfornumber = 'Mail ID is Required '

        setTimeout(() => {
          this.rulesfornumber = null
          // this.failure = null
          // this.toasttext = '';
          // this.snackbar1 = false
          // this.success = null
          // this.checkbox = false



        }, 3000); // 3000 milliseconds = 3 seconds 


      }
      else if (!this.email.match(regex)) {
        // Invalid email
        this.cardloader = false;
        this.checkbox = false

        this.rulesfornumber = 'Invalid Email';
        // this.email = null
        // Clear email text after 3 seconds
        setTimeout(() => {
          this.rulesfornumber = null;
        }, 3000); // 3000 milliseconds = 3 seconds
      }
      else if (this.email.match(regex)) {
        this.checkbox = true
        this.rulesfornumber = null
      }
      else {
        this.checkbox = false

      }
    },
    handleKeyUp(event) {
      
      if (this.checkbox == true) {
        // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
        if (event.key === 'Enter') {
        this.verifyEmail();
        ////console.log("verify mobile Enter pressed");

        // this.otpVerify();
        // this.verifyEmail();
          }
          
        // }
      }
    },

  },
  watch: {
    loader() {
      if (localStorage.getItem('hash')) {
        if (localStorage.getItem('hash').length == 10) {
          //this.errorMobNumber=false
          const l = this.loader
          this[l] = !this[l]

          setTimeout(() => (this[l] = false), 2000)

          this.loader = null
          if (this.emailDisp != true) {
            this.loadertext = "OTP Sent"
            // this.$v.$reset();
            setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
          }
        }
      }

    },
    panLoader() {
      const l = this.panLoader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 2000)

      this.panLoader = null
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {

        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.timerEnabled = false
          this.resendText = true
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyUp);
  },

  mounted() {

    this.stageis = localStorage.getItem("stageis")
    document.addEventListener('keyup', this.handleKeyUp);

    var data = localStorage.getItem('hash')
    this.mobile_num = atob(data)
    //console.log("this.mobile_num", this.mobile_num);
    // this.snackbar = true
    // this.toasttext = 'OTP Verified'
  }
}

</script>

<style lang="scss">
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .card-sty1 {
    border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 1px 2px 1px rgba(116, 116, 116, 0.20) !important;
    width: 100%;
    height: 98dvh;
    padding: 0;
    margin: 0;
  }

  .body-card {
    height: 90vh !important;
    /* overflow-y: scroll !important; */
    padding: 0;
    margin: 0;

  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .card-sty1 {
    border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 1px 2px 1px rgba(116, 116, 116, 0.20) !important;
    width: 100%;
    height: 98dvh;
    padding: 0;
    margin: 0;

  }

  .body-card {
    height: 90vh !important;
    /* overflow-y: scroll !important; */
    padding: 0;
    margin: 0;

  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .card-sty1 {
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    width: 90%;
    height: 80vh;
    margin: auto
  }

  .body-card {
    height: 100vh !important;


  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .card-sty1 {
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    width: 90%;
    height: 80vh;
    margin: auto
  }

  .body-card {
    height: 100vh !important;


  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .card-sty1 {
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    width: 90%;
    height: 80vh;
    margin: auto
  }

  .body-card {
    height: 100vh !important;
    // margin-top: 51px;s

  }
}




 
.custom-checkbox {
  font-size: 12px !important;
  /* Adjust label font size */
  transform: scale(0.8) !important;
  /* Adjust checkbox size */
  transform-origin: left center !important;
}
</style>

