<template >
  <div>
    <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute bottom color="success"
      outlined class="">
      <!-- <v-card> -->
      <v-icon color="green" v-if="success">
        "mdi mdi-check-circle
      </v-icon>
      <v-icon color="red" v-if="failure">
        mdi mdi-close-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- </v-card> -->
    </v-snackbar>
    <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute top color="success" outlined right class="">
      <!-- <v-card> -->
      <v-icon color="green" v-if="success">
        "mdi mdi-check-circle
      </v-icon>
      <v-icon color="red" v-if="failure">
        mdi mdi-close-circle
      </v-icon>
      {{ toasttext }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi mdi-close</v-icon>
        </v-btn>
      </template>
      <!-- </v-card> -->
    </v-snackbar>
    <v-container class="body-card">
      <v-card width="" height="" class="card-sty1  ">
        <v-progress-linear width="10" absolute color='#0037B7' height="12" value="2"></v-progress-linear>
        <v-toolbar color="transparent" dense flat class="mt-4 mb-3">
          <v-row no-gutters>
            <v-col cols="6">
              <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

            </v-col>
            <v-col cols="3" class="d-none d-lg-block">

            </v-col>
            <v-col class="stageselect" cols="3">
              <!-- {{ stageis   }} -->
              <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                item-disabled="disable" :items="stagesitemsis" v-model="stageis" flat solo background-color="#ffffff"
                class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 " label="Stage" hide-details
                append-icon="mdi-chevron-down"></v-select>

            </v-col>
          </v-row>
        </v-toolbar>
        <v-row class="px-8">
          <v-col cols="6" class="d-none d-lg-block">
            <v-card width="100%" class="rounded-0 d-none d-lg-block elevation-0">
              <img v-for="(image, index) in images" :key="index" :src="image"
                :style="{ display: index === currentIndex ? 'block' : 'none' }"
                style="height: 26em; display: flex; align-items: center; justify-content: center" width="100%" />
            </v-card>
          </v-col>
          <v-col lg="6" cols="12" class="cols-sm-12 pa-0 pa-2">
            <v-card v-if="otpdisp" width="100%" class="rounded-0 elevation-0">
              <v-card v-if="cardloader" fill-height style="background-color: rgba(255, 255, 255, 0.733);  " height="504"
                width="" class="rounded-0 elevation-0 loader">
                <div class="" style="margin-top: 138px;">
                  <v-progress-circular class="blocktext" :size="70" color="primary" indeterminate></v-progress-circular>
                  <p class="blocktext text-center subtitle-1  font-weight-bold">Please be wait....</p>
                </div>

              </v-card>
              <p class="title font-weight-bold mb-0 mt-2" style="">Sign Up Now</p>

              <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">Create your Partner account in
                minutes</p>
              <v-form ref="bankform" @submit.prevent="verifyMobile()" v-model="valid" lazy-validation>

                <v-text-field   color="black" hide-spin-buttons dense outlined min-height="5" rounded solo
                  :rules="mobilerules" label="Enter mobile number to begin" prepend-inner-icon="mdi-cellphone" flat
                  type="number" class="lable txtfld elevation-0" v-model="mobile_num" required prefix="+91" counter="10"
                  tabindex="1" style="white-space: nowrap; width: 93%; "
                  onkeyup="if(this.value.length > 9999999999) this.value = 999999999;">
                </v-text-field>
                <p class=" mt-10" style="font-size: 15px; color: #808080 !important;">By submitting your contact details,
                  you authorise Zebu to call
                  or text you for the purpose of account opening even though you may be registered on DND
                </p>
                <v-btn style="width: 93%;" color="black" elevation="2" small :disabled="!valid"
                  class="fltbtn py-6 otpbtn white--text mt-9 mb-4" rounded type="submit" tabindex="2">Verify
                </v-btn>
              </v-form>




            </v-card>
            <v-card v-else  width="100%" class="rounded-0  elevation-0">

              <p class="title font-weight-bold mt-2 mb-0">Verify Your Mobile</p>
              <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">An extra layer of protection by
                providing your phone number.</p>
              <v-text-field hide-details class="changefield" style="width: 93%;" prefix="+91" type="number"
                prepend-inner-icon="mdi-cellphone" color="black" hide-spin-buttons dense readonly min-height="5" flat
                v-model="mobile_num" solo>

                <template v-slot:append>
                  <p class=" font-weight-bold mt-3 " style="color:rgb(20, 0, 201)"><a style="font-size:12px;"
                      @click="changeMobileNumber()">change</a></p>
                </template>

              </v-text-field>


              <!-- <p class="">Create your Partner account in minutes</p> -->
              <!-- <v-form ref="bankform"> -->

              <!-- <v-text-field class="inputPriceOTP mx-4" prepend-icon="mdi-lock-clock" v-model="otp" label="OTP"
                  type="number" placeholder="Enter OTP" v-on:keyup="OtpValidation()" :error-messages="otpErrors"
                  @input="$v.otp.$touch()" @blur="$v.otp.$touch()" counter="4" required></v-text-field> -->

              <v-otp-input dense  outlined color="black" length="4" type="number" class="inputPriceOTP mt-9 mr-7" v-model="otp"
                @finish="otpVerify()" @input="$v.otp.$touch()" @blur="$v.otp.$touch()" counter="4" required></v-otp-input>
                <!--     <v-otp-input color="black" length="4" type="number" class="inputPriceOTP mt-9 mr-7" v-model="otp"
                @finish="otpVerify()" @input="$v.otp.$touch()" @blur="$v.otp.$touch()" counter="4" required></v-otp-input> -->

              <!-- </v-form> -->
              <!-- <p class="subtitle-2 font-weight-light mt-10">By submitting your contact details, you authorise Zebu to call
                or text you for the purpose of account opening even though you may be registered on DND
              </p> -->
              <div class="d-flex justify-space-between mt-n3 px- pr-5" style="margin-bottom: px; position: fixed;">
                <div>
                  <span class=" font-weight-medium red--text " style="font-size: 11px;">{{ otptext }}</span>
                </div>
                <div>
                </div>
              </div>
              <!-- <v-btn v-if="!otpstart" style="width: 93%;" color="black" elevation="2" small
                class=" py-6 font-weight-bold body-2 otpbtn white--text mt-10" rounded @click="otpVerify()"
                tabindex="2">Verify OTP
              </v-btn> -->
              <!-- <v-btn v-if="otpstart" style="width: 93%;" color="black" disabled elevation="2" small
                class="font-weight-bold body-2 py-6 otpbtn grey--text mt-10" rounded @click="otpVerify()"
                tabindex="2">Verify OTP
              </v-btn> -->
              <p class=" mt-5 mr-3" style="font-size: 15px; color: #808080 !important;">By submitting your contact
                details,
                you authorise Zebu to call
                or text you for the purpose of account opening even though you may be registered on DND
              </p>
              <v-btn @click="mobOtpResend()" :disabled="!resendText" color="black" style="width: 93%;" rounded
                class="fltbtn py-6 white--text font-weight-bold body-2 mt-2 mb-4">
                <span class="text-center " v-if="timerEnabled">Resend OTP in
                  {{ timerCount }}
                </span>
                <span class="text-center " v-if="resendText" style="">Don't receive OTP? resend</span>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import api from '@/api';
import imagePath from '../assets/inv1.svg'
import imagePath1 from '../assets/inv2.svg'
import imagePath2 from '../assets/inv3.svg'
import imagePath3 from '../assets/inv4.svg'
import imagePath4 from '../assets/inv5.svg'

export default {
  data: () => ({
    // Sign Up
    valid: false,
    mobilerules: [
      (v) => !!v || "Your mobile number is required",
      (v) =>
        /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number",
    ],
    rulesfornumber: null,
    // mobile_num: null,
    otp: '',
    stageis: 1,
    stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],
    otpdisp: true,
    snackbar: false,
    timerEnabled: false,
    timerCount: 120,
    resendText: false,
    toasttext: 'null',
    cardloader: false,
    errorOTPDisp: false,
    numberStart: true,
    otpstart: true,
    otptext: '',
    // test\
    mainurl: null,

    accountTypeItems: ['Saving Account', 'Current Account'],
    accountType: null,
    accNo: null,
    ifsc: null,
    retrybank: false,

    retrybankacc: false,
    retryCount: 0,
    fileattach1st: false,
    opencam: false,
    // cam
    isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    link: '#',
    photoData: null,
    localHash: null,
    dialog1: false,
    panStatus: null,
    pennyVerify: '',
    acctype: null,
    eduFile: false,
    DisplayData: [],
    aadhaApi: '',
    fatherNameval: '',
    corFather: '',
    bankApi: '',
    Slidervalue: 1,
    eduApi: '',
    // fatherNameval : '',
    // from kyc
    loader: null,
    panLoader: null,
    loading: false,
    // mobile_num: null,
    //errorMobNumber:false,
    logstatus: false,
    reference: null,
    // timerCount: 120,
    // timerEnabled: false,
    // resendText: false,
    email: null,
    emailDisp: false,
    emailOtpDisp: false,
    otpemail: null,
    // otp: null,
    checkbox: false,
    invalid: null,
    text: `You will receive an OTP on your number`,
    loadertext: "Verify",
    timeout: 1000,
    // otpdisp: true,
    mobdisp: true,
    isShowing: false,
    activePicker: null,
    date: null,
    menu: false,
    panDetaildisp: true,
    panFetchDetail: false,
    panInput: null,
    // accNo:s null,
    // ifsc: null,
    bank_name: null,
    bank_branch: null,
    bank_micr: null,
    enabledRegisteredAP: false,
    regMemberName: null,
    exchangeSelect: [],
    tradingExperience: null,
    qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
    occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
    qualification: null,
    occupation: null,
    mobile_num: null,
    errorDescPAN: false,
    KycFetchDisp: false,
    dob: null,
    aadharNo: null,
    add1: null,
    add2: null,
    add3: null,
    add4: null,
    address: null,
    country: null,
    district: null,
    state: null,
    pinCode: null,
    fatherName: null,
    gender: null,
    name: null,
    panFile: null,
    // aadharFile:null,
    bankFile: null,
    educationFiler: null,
    kycLoader: false,
    checkboxEsign: false,
    digioFile: null,
    digioMail: null,
    ekycStatus: false,
    step: 1,
    bankverifystatus: null,

    digiInformationShow: false,
    dialog: false,
    isDarkModeEnabled: false,
    // errorOTPDisp: false,
    erroreOTPDisp: false,
    // cardloader: false,
    ifscErrorDesc: false,
    // snackbar: false,
    // toasttext: 'null',
    // timeout: 2000,
    panInputLength: false,
    success: false,
    failure: false,
    isLesserThanResolution: false,
    // caur

    images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath, imagePath1, imagePath2, imagePath3, imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],

    currentIndex: 0,
  }),

  methods: {

    stageToo() {
      if (this.stageis == 1) {
        this.$router.push("/");
      }

      else if (this.stageis == 2) {
        this.$router.push("/pan");
      }
      else if (this.stageis == 3) {
        this.$router.push("/aadhar");
      }
      else if (this.stageis == 4) {
        this.$router.push("/bank");
      }
      else if (this.stageis == 5) {
        this.$router.push("/segments");
      }
      else if (this.stageis == 6) {
        this.$router.push("/fileupload");
      }
      else if (this.stageis == 7) {
        this.$router.push("/preview");
      }
      else if (this.stageis == 8) {
        this.$router.push("/submit");
      }
      // else if (this.stageis == 8) {
      //     this.$router.push("");
      // }
    },


    // Text field key
    // MobNumberValidation() {
    //   if (this.mobile_num) {
    //     // //console.log("I'm Innn",this.mobile_num)
    //     // //console.log("0 array",this.mobile_num[0])

    //     this.newVal = this.mobile_num.replace('-', '');
    //     ////console.log("New Val:", newVal);
    //     if (this.newVal.length >= 10) {

    //       this.mobile_num = this.newVal.slice(0, 10);
    //       if (this.newVal[0] == 6 || this.newVal[0] == 7 || this.newVal[0] == 8 || this.newVal[0] == 9) {
    //         // alert("valid")
    //         this.rulesfornumber = null;
    //         this.numberStart = false
    //         // alert("valid")

    //       } else {
    //         this.rulesfornumber = "Invalid Number"
    //         // setTimeout(() => {
    //         //   this.rulesfornumber = null;
    //         //     }, 2000);
    //         this.numberStart = true
    //         // alert("number not start with 6789 invalid")

    //       }
    //     } else {
    //       if (this.newVal.length < 10) {
    //         this.rulesfornumber = "10 Digits need to process"
    //         // setTimeout(() => {
    //         //   this.rulesfornumber = null;
    //         //     }, 1000);
    //       } else {
    //         this.rulesfornumber = null;
    //       }
    //       this.numberStart = true
    //     }
    //   } else {
    //     this.rulesfornumber = "Mobile number is Required";
    //   }
    // },
    // handleKeyUp(event) {

    //   if (this.stageis == 1) {
    //     // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
    //     if (event.key === 'Enter') {
    //       // this.verifyMobile();
    //       ////console.log("verify mobile Enter pressed");

    //       // this.otpVerify();
    //       // this.verifyEmail();
    //     }

    //     // }
    //   }
    // },

    // Verify phone
    verifyMobile() {
      if (this.$refs.bankform.validate() == true) {
        // this.$refs.bankform.validate()
        // this.$v.$touch()
        // alert("54")
        this.cardloader = true
        //this.errorMobNumber=false
        //////console.log("Mobile Number Sent :"+this.mobile_num,this.mobile_num.length)
        var hash = new Date().toLocaleString()
        var axiosThis = this
        localStorage.setItem('sesslog', btoa(hash))
        if (this.mobile_num) {
          // this.$v.$reset()

          if (this.mobile_num.length == 10 && this.mobile_num.length != 0) {
            var config = {
              method: 'post',
              url: api.api_url + '/mobile?mob=' + this.mobile_num + '&hash=' + btoa(hash),
              headers: {}
            };
            // https://apbe.zebull.in
            axios(config)
              .then(function () {
                // console.log(response);
                axiosThis.cardloader = false

                axiosThis.toasttext = 'OTP Sent';
                axiosThis.success = true;
                axiosThis.failure = false;
                axiosThis.snackbar = true;
                // axiosThis.mobdisp = false;
                axiosThis.otpdisp = false;
                axiosThis.timerEnabled = true;
                axiosThis.loader = 'loading'
                //console.log(JSON.stringify(response.data));
                //console.log("Responce came");
              })
              .catch(function (error) {
                console.log(error);
                axiosThis.cardloader = false
              });
          }
        }
        else {
          this.cardloader = false;

        }

      }
    },
    // Change number


    changeMobileNumber() {
      // this.mobdisp = true
      this.otpdisp = true;
      this.mobile_num = null;
      // this.loadertext = "Verify"
      this.timerEnabled = false;
      this.timerCount = 121;
      this.resendText = false;
      this.numberStart = true;
      this.otp = '';
      this.otptext = '';
      this.otpstart = true;

    },


    mobOtpResend() {
      var config = {
        method: 'post',
        url: api.api_url + '/resendmobotp?mob=' + this.mobile_num + '&hash=' + localStorage.getItem('sesslog'),
        headers: {}
      };
      var axiosThis = this
      axios(config)
        .then(function () {
          axiosThis.toasttext = 'OTP Resent';
          axiosThis.snackbar = true;
          axiosThis.success = true;
          axiosThis.failure = false;
          axiosThis.timerEnabled = true
          axiosThis.timerCount = 121
          axiosThis.resendText = false
          
        })
        .catch(function (error) {
          console.log(error);
        });
    },


    //OtpeValidation
    OtpValidation() {
      if (this.otp) {
        // this.errorOTPDisp = false;
        // //console.log(this.otp)
        var newVal = this.otp.replace('-', '');
        ////console.log("New Val:", newVal);
        if (newVal.length >= 4) {
          this.otp = newVal.slice(0, 4);
          this.otpstart = false

        } else if (newVal.length < 4) {

          this.otpstart = true

        }
      }
    },


    // //otpVerify
    otpVerify() {
      // this.$v.$touch()
      this.cardloader = true
      this.errorOTPDisp = false;
      if (this.otp) {
        if (this.otp.length == 4) {

          // this.$v.$reset()
          var config = {
            method: 'post',

            // url: api.api_url + '/bankDetails?mob=' + atob(localStorage.getItem('hash')) + '&bankName=' + this.bank_name + '&ifsc=' + this.ifsc + '&accountNo=' + this.accNo + '&micr=' + this.bank_micr + '&branch=' + this.bank_branch + '&bank_account_type=' + this.accountType,

            url: api.api_url + '/otpVerifyMobile?mob=' + this.mobile_num + '&code=' + this.otp + '&hash=' + localStorage.getItem('sesslog'),
            headers: {}
          };
          var axiosThis = this
          axios(config)
            .then(function (response) {
              axiosThis.cardloader = true
              axiosThis.DisplayData = response.data.fileuplres
              ////console.log(JSON.stringify(response.data));
              var responseOTP = response.data
              if (responseOTP['otp'] == 'success') {
                axiosThis.errorOTPDisp = false
                axiosThis.toasttext = "OTP Verified";
                axiosThis.otp = null;
                axiosThis.otptext = '';
                axiosThis.otpstart = true,
                  // axiosThis.mobile_num = null,
                  axiosThis.snackbar = true;
                axiosThis.success = true;
                axiosThis.failure = false;
                // console.log(axiosThis.mobile_num)
                localStorage.setItem('hash', btoa(axiosThis.mobile_num))
                localStorage.setItem('fileid', axiosThis.DisplayData[0].fileid)
                localStorage.setItem('mailid', axiosThis.DisplayData[0].email)
                sessionStorage.setItem('DisplayData', JSON.stringify(axiosThis.DisplayData));
                sessionStorage.setItem('panStatus', axiosThis.DisplayData[0].pan);
                sessionStorage.setItem('pennyVerify', axiosThis.DisplayData[0].peenydropVerify);
                if (axiosThis.DisplayData[0].adharimg) {
                  // Check if axiosThis.panApi is defined
                  const splitResult = axiosThis.DisplayData[0].adharimg.split('/ap-backend/');
                  //console.log("splitResult", splitResult);
                  if (splitResult.length > 1) {
                    // Check if the split operation was successful
                    var adhfileimg = `${api.api_url}/${splitResult[1]}`;
                    sessionStorage.setItem('aadhaApi', adhfileimg);
                  } else {
                    // Handle the case where the split operation failed
                    //console.error("Split operation failed.");
                  }

                } else {
                  // Handle the case where axiosThis.panApi is undefined
                  //console.error("axiosThis.panApi is undefined.");
                }
                if (axiosThis.DisplayData[0].bankFileLoc) {
                  // Check if axiosThis.panApi is defined
                  const splitResult = axiosThis.DisplayData[0].bankFileLoc.split('/ap-backend/');
                  //console.log("splitResult", splitResult);
                  if (splitResult.length > 1) {
                    // Check if the split operation was successful
                    var bankApi = `${api.api_url}/${splitResult[1]}`;
                    sessionStorage.setItem('bankApi', bankApi);
                  } else {
                    // Handle the case where the split operation failed
                    //console.error("Split operation failed.");
                  }

                } else {
                  // Handle the case where axiosThis.panApi is undefined
                  //console.error("axiosThis.panApi is undefined.");
                }
                sessionStorage.setItem('acctype', axiosThis.DisplayData[0].regMemberName);
                if (axiosThis.DisplayData[0].panimgloc) {
                  // Check if axiosThis.panApi is defined
                  const splitResult = axiosThis.DisplayData[0].panimgloc.split('/ap-backend/');
                  //console.log("splitResult", splitResult);
                  if (splitResult.length > 1) {
                    // Check if the split operation was successful
                    var panfileimg = `${api.api_url}/${splitResult[1]}`;
                    sessionStorage.setItem('panApi', panfileimg);

                  } else {
                    // Handle the case where the split operation failed
                    //console.error("Split operation failed.");
                  }

                } else {
                  // Handle the case where axiosThis.panApi is undefined
                  //console.error("axiosThis.panApi is undefined.");
                }
                if (axiosThis.DisplayData[0].edufileloc) {
                  // Check if axiosThis.panApi is defined
                  const splitResult = axiosThis.DisplayData[0].edufileloc.split('/ap-backend/');
                  //console.log("splitResult", splitResult);
                  if (splitResult.length > 1) {
                    // Check if the split operation was successful
                    var edufileimg = `${api.api_url}/${splitResult[1]}`;
                    sessionStorage.setItem('eduApi', edufileimg);
                  } else {
                    // Handle the case where the split operation failed
                    //console.error("Split operation failed.");
                  }

                } else {
                  // Handle the case where axiosThis.panApi is undefined
                  //console.error("axiosThis.panApi is undefined.");
                }

                // push
                if (responseOTP['ekycstatus'] == 'Yes') {
                  // axiosThis.step = 8;
                  // axios.Slidervalue = 100
                  //console.log("ekycstatus yes")

                  axiosThis.$router.push('/submit')
                  localStorage.setItem("stageis", 8)

                  axiosThis.ekycStatus = true;
                  axiosThis.cardloader = false;
                }
                else if (responseOTP['changes'] == 'email') {
                  //console.log("emailVerify yes")
                  
                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/pan')
                  localStorage.setItem("stageis", 3)
                  // push
                  // axios.Slidervalue = 20
                } else if (responseOTP['changes'] == 'pan') {
                  //console.log("emailVerify yes")

                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/aadhar')
                  localStorage.setItem("stageis", 4)
                  // push
                  // axios.Slidervalue = 20
                  // for aadhar page router
                } else if (responseOTP['changes'] == 'digilocker') {
                  //console.log("emailVerify yes")

                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/bank')
                  localStorage.setItem("stageis", 5)
                  // push
                  // axios.Slidervalue = 20
                } else if (responseOTP['changes'] == 'bank') {
                  //console.log("emailVerify yes")

                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/segments')
                  localStorage.setItem("stageis", 6)
                  // push
                  // axios.Slidervalue = 20
                } else if (responseOTP['changes'] == 'bank_details') {
                  //console.log("emailVerify yes")

                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/bank')
                  localStorage.setItem("stageis", 5)
                  // push
                  // axios.Slidervalue = 20
                } else if (responseOTP['changes'] == 'segment') {
                  
                  //console.log("emailVerify yes")

                  axiosThis.cardloader = false;
                  // axiosThis.$router.push('bank')
                  // localStorage.setItem("stageis", 7)
                  // console.log(axiosThis.mobile_num,'lklk')
                  var config = {
                    method: 'post',
                    url: api.api_url + '/segmentUpdation?mob=' + axiosThis.mobile_num,
                    headers: {}
                  };
                  // var axiosThis = this
                  axios(config)
                    .then(function (response) {
                      ////console.log(JSON.stringify(response.data));

                      ////console.log("ewq", response.data);
                      axiosThis.occupation = response.data['occupation']

                      //console.log("ewq", axiosThis.exchangeSelect);

                      //console.log("ewq", axiosThis.exchangeSelect);

                      axiosThis.acctype = response.data['RAAP']
                      sessionStorage.setItem('acctype', axiosThis.acctype)
                      axiosThis.pennyVerify = response.data.pennyDropVerify
                      sessionStorage.setItem('pennyVerify', axiosThis.pennyVerify)
                      axiosThis.panStatus = response.data.pan
                   

                      sessionStorage.setItem('panStatus', axiosThis.panStatus)
                      if ((axiosThis.acctype == "RA") && (axiosThis.pennyVerify == 'yes') && (axiosThis.panStatus == true)) {
                        axiosThis.$router.push('/preview')
                      } else {
                        axiosThis.$router.push('/fileupload')

                      }
                      // push
                      // axios.Slidervalue = 20
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
                } else if (responseOTP['changes'] == 'file') {
                  //console.log("emailVerify yes")

                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/preview')
                  localStorage.setItem("stageis", 7)
                  // push
                  // axios.Slidervalue = 20
                } else if (responseOTP['changes'] == 'esign') {
                  //console.log("emailVerify yes")
                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/submit')
                  localStorage.setItem("stageis", 7)
                }
                else {
                  axiosThis.otpdisp = true
                  // push
                  // alert("all done")

                  axiosThis.timerEnabled = false
                  axiosThis.resendText = false
                  axiosThis.timerCount = 121
                  axiosThis.logstatus = true
                  // axiosThis.emailDisp = true
                  axiosThis.cardloader = false;
                  axiosThis.$router.push('/email')
                  localStorage.setItem("stageis", 2)


                }
              }
              else {
                // axiosThis.toasttext = "OTP is invalid";
                axiosThis.otptext = 'OTP is invalid'

                axiosThis.otp = null;

                axiosThis.otpstart = true;
                // axiosThis.$v.$reset()
                //console.log('OTP Verify False dda');

                axiosThis.cardloader = false;
                setTimeout(() => {
                  axiosThis.otptext = '';
                }, 3000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else {
          this.otp = null
          // this.errorOTPDisp = true;
          // this.toasttext = 'OTP is Verified'
          this.cardloader = false;
        }
      }
      else {
        this.cardloader = false;
        // this.toasttext = 'OTP is Required'

      }

    },

    // caur
    startAutoChange() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % 4; // Increment index and loop back to 0
      }, 3000); // Change every 3 seconds (3000 milliseconds)
    },
    // otpErrors(){
    //   if(this.errorOTPDisp){
    //     this.toasttext = 'OTP is invalid'
    //   }else{

    //   }
    // },
  },
  // beforeDestroy() {
  //   document.removeEventListener('keyup', this.handleKeyUp);
  // },
  created() {
    let stages = 1;
    if (typeof stages === 'number') {
      for (let s = 0; s < this.stagesitemsis.length; s++) {
        this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
      }
      if (stages < this.stageis) {
        this.stageis = stages;
        this.stageToo();
      }
    }
    else {
      this.$router.push("/");
    }
    const thresholdResolution = 767;

    const screenWidth = window.innerWidth;

    if (screenWidth < thresholdResolution) {
      this.isLesserThanResolution = true;
    } else {
      this.isLesserThanResolution = false;

    }
  },
  watch: {
    loader() {
      if (this.mobile_num) {
        if (this.mobile_num.length == 10) {
          //this.errorMobNumber=false
          const l = this.loader
          this[l] = !this[l]

          setTimeout(() => (this[l] = false), 2000)

          this.loader = null
          if (this.emailDisp != true) {
            this.loadertext = "OTP Sent"
            // this.$v.$reset();
            setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
          }
        }
      }

    },
    panLoader() {
      const l = this.panLoader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 2000)

      this.panLoader = null
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {

        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
        if (value == 0) {
          this.timerEnabled = false
          this.resendText = true
        }

      },
      immediate: true // This ensures the watcher is triggered upon creation
    }

  },
  computed: {

    //  otpErrors() {
    //     // const errors = []
    //     ////console.log("OTP Valid : ", this.errorOTPDisp, this.otp)
    //     if (this.errorOTPDisp) {
    //         this.toasttext = 'OTP is invalid'
    //       // errors.push('Enter OTP is invalid')
    //       return errors
    //     }
    //     else {
    //       ////console.log(this.$v.otp.$dirty, this.$v.otp.required);
    //       if (!this.$v.otp.$dirty) return errors
    //       !this.$v.otp.required && errors.push('OTP is required')
    //       return errors
    //     }

    //   },
  },
  mounted() {
    // document.addEventListener('keyup', this.handleKeyUp);

    var plan = this.$route.query.id;
    //console.log(plan)
    if (plan) {
      localStorage.setItem('nalp', this.$route.query.id)
    }
    this.startAutoChange(); // Start auto-change when the component is mounted
    // if (localStorage.getItem("stageis")) {
    //   this.stageis = localStorage.getItem("stageis")
    //   this.stageToo();

    // } else {
    //   this.stageis = 1
    // }

    //console.log("this.stageis", this.stageis);
    // from kyc
    // document.addEventListener('keyup', this.handleKeyUp);
    // //console.log(this.$route.query)
    // if(this.$route.query.stageid == 4) {
    //   this.step = 2
    //   this.Slidervalue = 34
    //   this.$route.query.stageid == 0
    // }
    // var dataFromDigi = this.$route.query.code;
    // var ekycstatus = this.$route.query.data;

    // if (dataFromDigi) {
    //   this.kycLoader = true
    //   this.mobile_num = atob(localStorage.getItem('hash'))
    //   this.step = 3;
    //   this.Slidervalue = 36
    //   var config = {
    //     method: 'post',
    //     url: api.api_url + '/digiLockerFetch?mob=' + this.mobile_num + '&code=' + dataFromDigi,
    //     headers: {}
    //   };
    //   var axiosThis = this
    //   axios(config)
    //     .then(function (response) {
    //       ////console.log(JSON.stringify(response.data));
    //       var error = response.data['error']
    //       if (error) {
    //         window.location.href = "/";
    //         axiosThis.kycLoader = false
    //       }
    //       axiosThis.kycLoader = false
    //       axiosThis.KycFetchDisp = true
    //       axiosThis.name = response.data['name']
    //       axiosThis.dob = response.data['DoB']
    //       axiosThis.aadharNo = response.data['aadharNo']
    //       axiosThis.address = response.data['address']
    //       axiosThis.add1 = response.data['add1']
    //       axiosThis.add2 = response.data['add2']
    //       axiosThis.add3 = response.data['add3']
    //       axiosThis.add4 = response.data['add4']
    //       axiosThis.country = response.data['country']
    //       axiosThis.district = response.data['district']
    //       axiosThis.fatherName = response.data['fatherName']
    //       axiosThis.pinCode = response.data['pincode']
    //       axiosThis.state = response.data['states']
    //       axiosThis.gender = response.data['gender']
    //     })
    //     .catch(function (error) {
    //       //console.log(error);
    //     });
    // }
    // else if (ekycstatus) {
    //   ////console.log('ekyc status:', ekycstatus);
    //   this.step = 8;
    //   this.Slidervalue = 100
    //   this.ekycStatus = true;
    //   this.mobile_num = atob(localStorage.getItem('hash'))
    //   this.digioFile = localStorage.getItem('fileid')
    //   ////console.log('Disp : ', atob(localStorage.getItem('hash')), this.digioFile)
    //   var configs = {
    //     method: 'post',
    //     url: api.api_url + '/ekycUpdate?mob=' + this.mobile_num + '&fileid=' + this.digioFile,
    //     headers: {}
    //   };
    //   axios(configs)
    //     .then(function (response) {
    //       //console.log(JSON.stringify(response.data));
    //     })
    //     .catch(function (error) {
    //       //console.log(error);
    //     });
    // }
    // else {
    //   // localStorage.removeItem("hash");
    //   // localStorage.removeItem("fileid");
    //   // localStorage.removeItem("sesslog");
    // }
    this.mainurl = api.api_url;
    //   if (this.step == 1) {
    //     this.Slidervalue = 12
    //   }
    //   else if (this.step == 2) {
    //     this.Slidervalue = 24
    //   }else if (this.step == 3) {
    //     this.Slidervalue = 36
    //   }else if (this.step == 4) {
    //     this.Slidervalue = 48
    //   }else if (this.step == 5) {
    //     this.Slidervalue = 60
    //   }else if (this.step == 6) {
    //     this.Slidervalue = 72
    //   }else if (this.step == 7) {
    //     this.Slidervalue = 84
    //   }else if (this.step == 8) {
    //     this.Slidervalue = 100
    //   }
  },
}

</script>

<style lang="scss" >
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .card-sty1 {
    border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 1px 2px 1px rgba(116, 116, 116, 0.20) !important;
    width: 100%;
    border-radius: 1px !important;

    height: 98dvh;
    padding: 0;
    margin: 0;
  }

  .scrollbar {
    // height: 100 px;
    width: 100%;

  }

  .body-card {
    height: 90vh !important;
    /* overflow-y: scroll !important; */
    padding: 0;
    margin: 0;

  }

  .fltbtn {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .fltbtn1 {
    position: fixed;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .card-sty1 {
    border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 1px 2px 1px rgba(116, 116, 116, 0.20) !important;
    border-radius: 1px !important;
    width: 100%;
    height: 98dvh;
    padding: 0;
    margin: 0;

  }

  .body-card {
    height: 90vh !important;
    /* overflow-y: scroll !important; */
    padding: 0;
    margin: 0;

  }

  .stageselect {
    .v-input__slot {
      width: 170px !important;
      height: 30px !important;
      font-size: 16px !important;
      margin-left: 120px;
    }
  }

  // .fltbtn{
  //   position: fixed;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .card-sty1 {
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    width: 90%;
    height: 80vh;
    margin: auto
  }

  .body-card {
    height: 100vh !important;

    padding-top: 64px;

  }

  .stageselect {
    .v-input__slot {
      width: 170px !important;
      height: 30px !important;
      font-size: 16px !important;
      margin-left: 200px;
    }
  }

}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .card-sty1 {
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    width: 90%;
    height: 80vh;
    margin: auto
  }

  .body-card {
    height: 100vh !important;
    overflow-y: hidden;
    padding-top: 64px;

  }

  .stageselect {
    .v-input__slot {
      width: 170px !important;
      height: 30px !important;
      font-size: 16px !important;
      margin-left: 70px;
    }
  }
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .card-sty1 {
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    width: 90%;
    height: 80vh;
    margin: auto
  }

  .scrollbar {
    height: 440px;
    width: 100%;
    overflow-y: hidden;

  }

  .body-card {
    height: 100vh !important;
    padding-top: 64px;


  }

  .stageselect {
    .v-input__slot {
      width: 170px !important;
      height: 30px !important;
      font-size: 16px !important;
      margin-left: 55px;
    }
  }

}




// ::-webkit-scrollbar {
//   display: none;
// }

.loader {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;

}

.blocktext {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.stageselect {
  .v-input__slot {
    width: 170px !important;
    height: 30px !important;
    font-size: 16px !important;
    margin-left: px;
  }
}

.v-text-field__prefix {
  text-align: right;
  padding-right: 4px;
  font-size: 16px;
}

input#input-139 {
  font-size: 13px !important;
  font-weight: 569 !important;


}

.lable .v-label {
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 569 !important;
  padding-left: 5px !important;
}

.txtfld {
  .v-text-field__details {
    width: 100% !important;
  }
}

.v-text-field__prefix {
  font-size: 13px !important;
  font-weight: 569 !important;
}

.v-input__slot {
  height: 47px !important;
}

.carousel {
  ::before {
    display: none !important;
  }
}

.carousel {
  .v-btn--icon.v-btn--round.theme--dark.v-size--default {
    display: none !important;

  }
}

.carousel {
  .v-carousel__controls {
    display: none !important;

  }
}

.text-field--outlined>.v-input__control>.v-input__slot {
  align-items: stretch;
  min-height: 56px;
  border-radius: 20px !important;
}

.changefield {
  border: 2px solid rgb(177, 177, 177);
  border-style: dashed;

  border-radius: 20px;
}

.v-text-field--outlined>.v-input__control>.v-input__slot {
  // align-items: stretch;
  min-height: 73px;
}

.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-radius: inherit !important;
  border-color: #f0f0f0 !important;
  border-width: 2px !important;
  width: 100%;
}

.v-menu__content.theme--light.menuable__content__active {
  border-radius: 24px;
}

.inputPriceOTP .v-text-field__slot{
  border: 2px solid #00000056 !important;
  border-radius: 4px;
}
// .v-text-field.v-text-field--enclosed .v-text-field__details {
//     padding: 0 12px;
//     width: px !important;  
// }
</style>

