<template>
    <div>
        <v-container  class="body-card">
            <v-card width="" height="" class="card-sty1  ">
                <v-progress-linear absolute color='#0037B7' height="12" value="100"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <!-- <v-col cols="3">
                            <p class="mt-5 mb-0 font-weight-bold sec-ff" style="color:#0037B7;">Sign up 1 of 2</p>
                        </v-col>
                        <v-col cols="3">
                            <v-select style="max-width: 130px !important;" dense @change="stageToo()" item-text="val"
                                item-value="key" :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3"
                                label="Stage" hide-details append-icon="mdi-chevron-down"></v-select>
                        </v-col> -->
                    </v-row>
                </v-toolbar>
                <v-row class="px-8">
                    <v-col  class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0  elevation-0">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col class="cols-sm-12">
                        <v-card width="100%" class="rounded-0 elevation-0">
                            <div class="pa-4 text-center">
                                <v-card-text>
                                    <h2>Congratulations!</h2>
                                </v-card-text>
                                <v-icon class="mx-auto" size="70" color="green"
                                    width="250">mdi-checkbox-marked-circle</v-icon>
                                <v-card-text style="padding-top:50px;">Thank you for becoming a Partner of
                                    Zebu.</v-card-text>
                                <v-card-text style="margin-top:-30px;">Our team will reach you shortly.</v-card-text>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
// import axios from 'axios';
import api from '@/api';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'
export default {
    data: () => ({
        // Sign Up
        images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],

    currentIndex: 0,
        mobile_num: null,
        otp: '',
        stageis: 1,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' }, ],
        otpdisp: true,
        snackbar: false,
        timerEnabled: false,
        timerCount: 120,
        resendText: false,
        toasttext: 'null',
        cardloader: false,
        errorOTPDisp: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        errorDescPAN: '',
        panbtn: false,
        dataFromDigiCame: false,
        // test\
        mainurl: null,
        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        retrybank: false,

        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: null,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: [],
        aadhaApi: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 1000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: null,
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        // errorDescPAN: '',
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: null,
        gender: null,
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,

    }),
    created() {
    this.startAutoChange();
  },
    methods: {
        startAutoChange() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % 4; // Increment index and loop back to 0
      }, 3000); // Change every 3 seconds (3000 milliseconds)
    },
        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },


    },
    watch: {
        loader() {
            if (this.mobile_num) {
                if (this.mobile_num.length == 10) {
                    //this.errorMobNumber=false
                    const l = this.loader
                    this[l] = !this[l]

                    setTimeout(() => (this[l] = false), 2000)

                    this.loader = null
                    if (this.emailDisp != true) {
                        this.loadertext = "OTP Sent"
                        this.$v.$reset();
                        setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
                    }
                }
            }

        },
        panLoader() {
            const l = this.panLoader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 2000)

            this.panLoader = null
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value == 0) {
                    this.timerEnabled = false
                    this.resendText = true
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    computed: {

    },
    mounted() {
        // from kyc
          
        this.mainurl = api.api_url;
        localStorage.clear();
        sessionStorage.clear()
        //   if (this.step == 1) {
        //     this.Slidervalue = 12
        //   }
        //   else if (this.step == 2) {
        //     this.Slidervalue = 24
        //   }else if (this.step == 3) {
        //     this.Slidervalue = 36
        //   }else if (this.step == 4) {
        //     this.Slidervalue = 48
        //   }else if (this.step == 5) {
        //     this.Slidervalue = 60
        //   }else if (this.step == 6) {
        //     this.Slidervalue = 72
        //   }else if (this.step == 7) {
        //     this.Slidervalue = 84
        //   }else if (this.step == 8) {
        //     this.Slidervalue = 100
        //   }
    },
}

</script>
<style lang="scss">
.input#input-19 {
    font-size: 9px !important;
}

.card-sty1 {
    /* border: 1px solid #F2F2F2 !important; */
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    /* margin-left: -1px !important; */
    /* margin-right: 2px !important; */
}

.body-card {
    height: 100vh !important;


    /* overflow-y: scroll !important; */
}
</style>