import Vue from 'vue'
import VueRouter from 'vue-router'
import Signup from '../components/SignUp.vue'
import EmailPage from '../components/EmailPage.vue'
import PanPage from '../components/PanPage.vue'
import AadharPage from '../components/AadharPage.vue'
import BankPage from '../components/BankPage.vue'
import SegmentSelection from '../components/SegmentSelection.vue'
import FileUpload from '../components/FileUplode.vue'
import PreviewPage from '../components/PreviewPage.vue'
import ShareGen from '../components/ShareGen.vue'
import SubmitPage from '../components/SubmitPage.vue'

// test
import TestPage from '../components/test/SignUp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/email',
    name: 'email',
    component: EmailPage
    
  },
  {
    path: '/pan',
    name: 'pan',
    component: PanPage
    
  },
  {
    path: '/aadhar',
    name: 'aadhar',
    component: AadharPage
    
  },
  {
    path: '/bank',
    name: 'bank',
    component: BankPage
    
  },
  {
    path: '/segments',
    name: 'segments',
    component: SegmentSelection
    
  },
  {
    path: '/fileupload',
    name: 'fileupload',
    component: FileUpload
    
  },
  {
    path: '/preview',
    name: 'preview',
    component: PreviewPage
    
  },
  {
    path: '/share-gen',
    name: 'share-gen',
    component: ShareGen
    
  },
  {
    path: '/submit',
    name: 'submit',
    component: SubmitPage
    
  },
// test

  {
    path: '/test',
    name: 'test',
    component: TestPage
    
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
