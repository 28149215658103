<template>
    <div>
        <v-container  class="body-card">
            <v-card  class="card-sty1  ma-auto">
                <v-progress-linear absolute color='#0037B7' height="12" value="0"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <!-- <v-col cols="3">
                            <p class="mt-5 mb-0 font-weight-bold sec-ff" style="color:#0037B7;">Sign up 1 of 2</p>
                        </v-col>
                        <v-col cols="3">
                            <v-select style="max-width: 130px !important;" dense @change="stageToo()" item-text="val"
                                item-value="key" :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3"
                                label="Stage" hide-details append-icon="mdi-chevron-down"></v-select>
                        </v-col> -->
                    </v-row>
                </v-toolbar>
                <v-row class="px-8 ">
                    <v-col class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0  elevation-0">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col class="pa-0 cols-sm-12" >
                        <!-- <v-card width="100%" class="rounded-0 elevation-0" style="max-height: 500px; overflow-y: auto;"> -->
                        <v-card width="100%" class="rounded-0 elevation-0" style="max-height: 500px; overflow-y: auto;">
                            <v-app>
                                <!-- <div style="padding-top:60px;"></div> -->
                                <v-card class="mx-auto" elevation="0" >
                                   
                                    <v-card-text class="pa-0 mt-4">
                                        <v-row>
                                            <v-col lg="4">
                                                <h2 style="padding-left:10px; color: black; " class="pl-0  mt-n1 ml-2">
                                                    Sharing
                                                    Ratio</h2>
                                            </v-col>
                                            <v-col lg="3">


                                                <v-select class="txtfiel elevation-0 rounded-pill mr-4 ml-auto " outlined
                                                    dense flat solo v-model="branch_client_type"
                                                    style="float:right;width:80%;margin-top:-17px;"
                                                    background-color="#ffffff" :items="client_type_items"
                                                    label="Client Type"></v-select>
                                            </v-col>
                                            <v-col lg="3">
                                                <v-select outlined dense flat
                                                    class="txtfiel elevation-0 rounded-pill mr-4 ml-auto "
                                                    background-color="#ffffff" solo v-model="branch"
                                                    style="float:right;width:100%;margin-top:-17px;" :items="items"
                                                    label="Branch Code"></v-select>
                                            </v-col>
                                        </v-row>
                                        <v-data-table :headers="headers" :items="desserts" disable-sort
                                            :hide-default-footer="true">
                                            <template v-slot:item="row">
                                                <tr>
                                                    <td>
                                                        <h4>{{ row.item.sharing_seg.split(' ')[0] }}</h4>
                                                        <small>{{ row.item.sharing_seg.split(' ')[1] }}</small>
                                                    </td>
                                                    <td>{{ row.item.ap_sharing }}</td>
                                                    <td>{{ row.item.zebu_sharing }}</td>
                                                    <td>{{ row.item.min_to_zebu }}</td>
                                                    <td><v-icon small class="mr-2"
                                                            @click="editItem(row.item, row.item.sharing_seg.split(' '))"
                                                            style="float:right">
                                                            mdi-pencil
                                                        </v-icon>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-slot:top>


                                                <v-dialog v-model="dialog" max-width="500px">

                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="text-h6">{{ formTitle }}</span>
                                                            <v-spacer></v-spacer>
                                                            <!-- <v-chip label>AP/RA Sharing: -->
                                                                <v-chip label>AP Sharing:
                                                                <span v-if="edit_zebu_sharing && formTitle != 'Options'">{{
                                                                    100 -
                                                                    parseInt(edit_zebu_sharing.split('%')[0]) }}%</span>
                                                                <span v-if="formTitle == 'Options'"> ---</span>
                                                            </v-chip>
                                                        </v-card-title>

                                                        <v-card-text style="padding-top:25px;">
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col>
                                                                        <v-select v-if="formTitle != 'Options'"
                                                                            v-model="edit_zebu_sharing"
                                                                            :items="['15%', '20%', '25%', '30%', '35%', '40%', '50%', '60%', '70%', '80%', '90%']"
                                                                            label="Zebu Sharing"></v-select>
                                                                       
                                                                        <v-text-field prefix="Rs. "
                                                                            v-if="formTitle == 'Options'" type="number"
                                                                            :min=5 :max=50
                                                                            :messages="[edit_min_to_zebu < 5 ? 'Min Rs. 5' : '' || edit_min_to_zebu > 50 ? 'Max Rs. 50' : '']"
                                                                            v-model="edit_min_to_zebu"
                                                                            label="Minimum to Zebu"></v-text-field>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <v-select
                                                                            v-if="formTitle != 'Options' && formTitle == 'Equities Delivery'"
                                                                            v-model="edit_min_to_zebu"
                                                                            :items="['1500/cr', '2000/cr', '2500/cr', '3000/cr', '4000/cr', '5000/cr']"
                                                                            label="Minimum to Zebu"></v-select>
                                                                        <v-select
                                                                            v-if="formTitle != 'Options' && formTitle != 'Equities Delivery'"
                                                                            v-model="edit_min_to_zebu"
                                                                            :items="['150/cr', '200/cr', '250/cr', '300/cr', '400/cr', '500/cr']"
                                                                            label="Minimum to Zebu"></v-select>

                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                        </v-card-text>

                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="close">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn color="blue darken-1" text @click="save">
                                                                Save
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>

                                            </template>
                                         
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-icon small class="mr-2" @click="editItem(item)" style="float:right">
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-btn color="primary" @click="initialize">
                                                    Reset
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                        <div style="padding-top:30px;">
                                            <v-btn style="width: 93%" :disabled="!branch || !branch_client_type" rounded color="black"
                                                class="otpbtn fltbtn mb-4 white--text py-6 " 
                                                @click="generate_link">Generate</v-btn>
                                        </div>
                                        <v-text-field color="black" v-if="share_link" label="Share to your client"
                                            v-model="share_link"
                                            :append-icon="share_link_icon ? 'mdi-clipboard-check' : 'mdi-clipboard'"
                                            @click:append="copy"></v-text-field>
                                    </v-card-text>
                                </v-card>
                            </v-app>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'

export default {
    data: () => ({
        items: [],
        branch: null,
        branch_client_type: "AP",
        // client_type_items: ["AP", "RA"], 
        client_type_items: ["AP"],
        tab: null,
        dialog: false,
        dialogDelete: false,
        formTitle: null,
        edit_zebu_sharing: null,
        edit_min_to_zebu: null,
        share_link: null,
        share_link_icon: false,
        images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],

        currentIndex: 0,
        headers: [
            {
                text: 'Sharing Segment',
                align: 'start',
                sortable: false,
                value: 'sharing_seg',
            },
            { text: 'AP/RA Sharing', value: 'ap_sharing' },
            { text: 'Zebu Sharing', value: 'zebu_sharing' },
            { text: 'Min to Zebu', value: 'min_to_zebu' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            sharing_seg: '',
            ap_sharing: 0,
            zebu_sharing: 0,
            min_to_zebu: 0,
        },
        defaultItem: {
            sharing_seg: '',
            ap_sharing: 0,
            zebu_sharing: 0,
            min_to_zebu: 0,
        },
    }),

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    mounted() {
        var config = {
            method: 'post',
            // url: 'http://192.168.5.128:5005/get_branch_code',
            url: 'https://apbe.zebull.in/get_branch_code',
            headers: {}
        };
        var axiosThis = this
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                axiosThis.items = response.data['data'];
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    created() {
        this.initialize()
        this.startAutoChange();
    },

    methods: {
        startAutoChange() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % 4; // Increment index and loop back to 0
            }, 3000); // Change every 3 seconds (3000 milliseconds)
        },
        initialize() {
            this.desserts = [
                {
                    sharing_seg: 'Equities Intraday',
                    ap_sharing: '70%',
                    zebu_sharing: '30%',
                    min_to_zebu: '300/cr',
                },
                {
                    sharing_seg: 'Equities Delivery',
                    ap_sharing: '70%',
                    zebu_sharing: '30%',
                    min_to_zebu: '3000/cr',
                },
                {
                    sharing_seg: 'Futures FNO,CDS,COM',
                    ap_sharing: '70%',
                    zebu_sharing: '30%',
                    min_to_zebu: '300/cr',
                },
                {
                    sharing_seg: 'Options FNO,CDS,COM',
                    ap_sharing: '-',
                    zebu_sharing: '-',
                    min_to_zebu: 'Rs. 5',
                }
            ]
        },

        editItem(item, title) {
            if (title[0] == 'Equities') {
                this.formTitle = title[0] + " " + title[1]
            }
            else {
                this.formTitle = title[0]
            }
            if (title[0] != 'Options') {
                console.log(item['zebu_sharing'])
                this.edit_zebu_sharing = item['zebu_sharing']
                this.edit_min_to_zebu = item['min_to_zebu']
            }
            else {
                this.edit_zebu_sharing = 10
                this.edit_min_to_zebu = 5
            }

            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        copy() {
            this.share_link_icon = !this.share_link_icon;
            console.log("Copied")
        },
        generate_link() {
            var data = window.location.protocol + '//' + window.location.hostname + '/?id=' + btoa(unescape(encodeURIComponent(JSON.stringify(this.desserts) + '$' + this.branch + '$' + this.branch_client_type)));
            console.log("Generate Link :" + data)
            this.share_link = data;
            navigator.clipboard.writeText(data);
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            console.log(this.formTitle)
            var title = this.formTitle;
            if (title == 'Options' || title == 'Futures') {
                title = title + ' FNO,CDS,COM'
            }
            for (let i = 0; i < this.desserts.length; i++) {
                if (this.desserts[i]['sharing_seg'] == title) {
                    if (this.formTitle != 'Options') {
                        console.log(this.desserts[i]['sharing_seg'])
                        this.desserts[i]['zebu_sharing'] = this.edit_zebu_sharing
                        this.desserts[i]['ap_sharing'] = (100 - parseInt(this.edit_zebu_sharing.split('%')[0])).toString() + '%'
                        this.desserts[i]['min_to_zebu'] = this.edit_min_to_zebu
                        this.close();
                    }
                    else {
                        if (this.edit_zebu_sharing >= 5 && this.edit_zebu_sharing <= 50 && this.edit_min_to_zebu >= 5 && this.edit_min_to_zebu <= 50) {

                            this.desserts[i]['zebu_sharing'] = 'Rs. ' + this.edit_zebu_sharing.toString()
                            this.desserts[i]['ap_sharing'] = '-'
                            this.desserts[i]['min_to_zebu'] = 'Rs. ' + this.edit_min_to_zebu.toString()
                            this.close();

                        }
                    }
                }
            }
        },
    },

}
</script>

<style lang="scss">
.txtfiel{
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 12px;
    width: 170px;
}
}
.card-sty1 {
    border: 1px solid #F2F2F2 !important;
    box-shadow: 0px 1px 2px 1px rgba(116, 116, 116, 0.20) !important;
}

.body-card {
    height: 90vh !important;
    overflow-y: scroll !important;
}</style>