<template>
    <div>
        <v-snackbar v-if="isLesserThanResolution" v-model="snackbar" :timeout="timeout" absolute bottom color="success"
            outlined class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-snackbar v-else v-model="snackbar" :timeout="timeout" absolute top color="success" outlined right class="">
            <!-- <v-card> -->
            <v-icon color="green" v-if="success">
                "mdi mdi-check-circle
            </v-icon>
            <v-icon color="red" v-if="failure">
                mdi mdi-close-circle
            </v-icon>
            {{ toasttext }}

            <template v-slot:action="{ attrs }">
                <v-btn icon color="green" text v-bind="attrs" @click="snackbar = false">
                    <v-icon>mdi mdi-close</v-icon>
                </v-btn>
            </template>
            <!-- </v-card> -->
        </v-snackbar>
        <v-container class="body-card">
            <v-card width="" height="" class="card-sty1  ">
                <v-progress-linear absolute color='#0037B7' height="12" value="48"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <v-col cols="3" class="d-none d-lg-block">

                        </v-col>
                        <v-col class="stageselect" cols="3">
                            <!-- {{ stageis   }} -->

                            <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                                item-disabled="disable" :items="stagesitemsis" v-model="stageis" flat solo
                                background-color="#ffffff" class="txtfield elevation-0 rounded-pill mr-4 ml-auto mt-3 "
                                label="Stage" hide-details append-icon="mdi-chevron-down"></v-select>

                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row class="px-8">
                    <v-col class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0 elevation-0">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }" style="height: 26em;
  display: flex;
  align-items: center;
  justify-content: center" width="100%" />
                        </v-card>
                    </v-col>
                    <v-col class="pl-2 cols-sm-12">

                        <v-card width="" height="" class="rounded-0 elevation-0 scrollhighlight" style="overflow: scroll;">
                            <div style="display: inline-flex; overflow: hidden;">

                                <div class="">

                                    <p class="title font-weight-bold mb-0">Bank Account Details</p>
                                    <p class="   mt-1 mb-6" style="font-size: 15px; color: #808080 !important;">Provide Bank
                                        account details wherein you are
                                        sole/primary holder</p>
                                    <v-card v-if="cardloader" fill-height
                                        style="background-color: rgba(255, 255, 255, 0.733);  " height="504" width=""
                                        class="rounded-0 elevation-0 loader">
                                        <div class="" style="margin-top: 138px;">
                                            <v-progress-circular class="blocktext" :size="70" color="primary"
                                                indeterminate></v-progress-circular>
                                            <p class="blocktext text-center subtitle-1  font-weight-bold">Processing please
                                                be wait....</p>
                                        </div>

                                    </v-card>

                                    <v-form ref="bankform">


                                        <!--  -->
                                        <!-- hide-spin-buttons dense outlined min-height="5" rounded solo flat inner prefix -->
                                        <v-text-field color="black" hide-spin-buttons dense outlined prefix="      "
                                            min-height="5" rounded solo flat prepend-inner-icon="mdi-bank"
                                            :disabled="bankverifystatus" class="my-1 lable" type="number"
                                            :rules="[v => !!v || 'Account Number is required',]" v-model="accNo"
                                            label="Account Number" required @input="$v.accNo.$touch()"
                                            @blur="$v.accNo.$touch()"></v-text-field>

                                        <v-text-field color="black" hide-spin-buttons dense outlined min-height="5" rounded
                                            solo flat prepend-inner-icon="mdi-credit-card" :disabled="bankverifystatus"
                                            class="mb-1 mt-1 lable txtfld" v-model="ifsc" prefix="       "
                                            :rules="[Rulesforifsc]" label="IFSC Code" @input="ifscFetchDetails"
                                            oninput="this.value = this.value.toUpperCase()" v-on:keyup="ifscValidation()"
                                            @blur="$v.ifsc.$touch()" counter="11" required></v-text-field>


                                        <v-select color="black" v-if="!bankverifystatus" hide-spin-buttons dense
                                            prefix="      " outlined min-height="5" rounded solo flat
                                            :rules="[v => !!v || 'Account Type is required',]" :items="accountTypeItems"
                                            v-model="accountType" prepend-inner-icon=" mdi-card-account-details"
                                            label="Account Type" required @blur="$v.accountType.$touch()"
                                            class="my-1 lable"></v-select>

                                    </v-form>
                                    <span v-if="bank_name && !bankverifystatus">
                                        <p class="font-weight-bold body-2 pl-3 mb-0" style="color: blue;">Bank Details</p>
                                        <strong style="padding-left:12px" class=""> {{ bank_name }} </strong>
                                        <p style="font-size:12px;padding-left:12px">{{ bank_branch }} | {{ bank_micr }}
                                        </p>
                                    </span>
                                    <v-btn v-if="!bankverifystatus && !btndis" :disable=btndis color="black" rounded
                                        @click="bankDetailUpdate()" class="fltbtn otpbtn py-6 my-4 white--text"
                                        style="width: 93%;">Continue</v-btn>
                                    <v-btn v-if="btndis" disabled color="black" rounded @click="bankDetailUpdate()"
                                        class="fltbtn otpbtn py-6 my-4 white--text" style="width: 93%;">Continue</v-btn>
                                </div>

                            </div>
                            <div v-if="bankverifystatus">
                                <v-card class="mx- ma-0  elevation-0"
                                    style="background-color:#E5EBEC; border-radius: 20px;">
                                    <v-col class="pa-4">
                                        <p class="mb-0 d-flex"> <span class="text-center mx-auto"><v-icon
                                                    v-if="accstatus == 'active'" color="primary"
                                                    x-large>mdi-account-check-outline</v-icon><v-icon color="primary" v-else
                                                    x-large>mdi-account-cancel-outline</v-icon></span> </p>

                                        <p class="mb-0 caption font-weight-bold"
                                            style="font-size:11px;text-align:center;color:rgb(0, 0, 0)">{{ retryCount
                                            }}/3
                                        </p>
                                        <p class="mb-0 caption font-weight-bold"
                                            style="font-size:14px;text-align:center;color:rgb(0, 0, 0)">Account Status
                                            : {{
                                                accstatus }}</p>

                                        <p class="mb-0 mt-2 caption font-weight-bold"
                                            style="font-size:14px;text-align:center;color:rgb(0, 0, 0)"> {{
                                                errorMessage }}</p>
                                        <p v-if="retrybank" class="mb-0 mt-2 caption font-weight-bold"
                                            style="font-size:11px;text-align:center;color:rgb(0, 0, 0)">Transaction
                                            Number
                                            : {{ tran_no }}</p>

                                        <p v-if="retrybankacc" class="mb-0 caption font-weight-bold"
                                            style="font-size:14px;text-align:center;color:rgb(0, 0, 0)">Account Name : {{
                                                bankUname }}</p>


                                    </v-col>

                                </v-card>
                                <v-row class=" ">
                                    <v-col v-if="this.retryCount == 3">
                                        <v-btn style="width: 93%;" rounded color="black"
                                            class="fltbtn white--text py-6 mt-3 mb-4 font-weight-bold flex-nowrap"
                                            @click="changeBank()">Change</v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn rounded v-if="bankverifystatus" style="width: 93%;" color="black"
                                            class="fltbtn white--text py-6 mt-3 mb-4 font-weight-bold flex-nowrap"
                                            @click="checkforbank()">{{
                                                retrybank ? "Retry" : "Proceed" }}</v-btn>
                                    </v-col>

                                    <v-progress-linear color="#1976D2" :active="cardloader" indeterminate rounded
                                        height="3"></v-progress-linear>

                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import api from '@/api';
import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'
export default {
    data: () => ({
        // Sign Up
        currentIndex: 0,
        images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],
        Rulesforifsc: null,
        mobile_num: null,
        otp: '',
        stageis: 4,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' },],

        otpdisp: true,
        snackbar: false,
        timerEnabled: false,
        timerCount: 120,
        resendText: false,
        toasttext: 'null',
        cardloader: false,
        btndis: false,
        errorOTPDisp: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        // test\
        mainurl: null,

        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        isLesserThanResolution: false,
        retrybank: false,

        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: null,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: [],
        aadhaApi: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 1000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: null,
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        errorDescPAN: false,
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: null,
        gender: null,
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,
        bankchange: false,
    }),

    methods: {

        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },
        // ifsc
        ifscValidation() {
            //this.ifscErrorDesc=false

            if (this.ifsc) {
                var newVal = this.ifsc.replace('-', '');
                //////console.log("New Val:",newVal);
                if (this.ifsc.length > 11) {
                    this.ifsc = newVal.slice(0, 11);
                }
            }
        },

        // change bank
        changeBank() {
            this.bankverifystatus = false;
            // this.accNo = null
            // this.ifsc = null
            this.retrybank = false
            // this.accountType = null
            // this.bank_name = null;
            // this.bank_branch = null;
            // this.bank_micr = null;
            this.bankchange = true
        },
        startAutoChange() {
            setInterval(() => {
                this.currentIndex = (this.currentIndex + 1) % this.images.length;
            }, 5000); // Change every 5 seconds
        },
        // retry bank
        checkforbank() {

            if (this.retrybank) {
                this.bankverifystatus = false;
                this.accNo = null
                this.ifsc = null
                this.retrybank = false
                this.accountType = null
                this.bank_name = null;
                this.bank_branch = null;
                this.bank_micr = null;
                this.bankchange = true
            } else {

                localStorage.setItem("stageis", 5)

                this.$router.push("/segments")

                // this.step = 5
                // this.Slidervalue = 60
                localStorage.setItem(this.localHash, "0");

            }
            if (!this.bank_name) {
                this.ifscFetchDetails()
            }

        },
        async ifscFetchDetails() {
            //////console.log("Ifsc:"+this.ifsc.length);
            if (this.ifsc == '') {
                this.Rulesforifsc = "Required Field"
            }
            else if (this.ifsc) {
                var ifsvc = this.ifsc;
                if (this.ifsc.length > 11) {
                    ifsvc = this.ifsc.slice(0, 11);

                } else if (this.ifsc.length < 11) {

                    this.Rulesforifsc = "11 Numbers needed"

                } else if (this.ifsc.length == 11) {
                    this.Rulesforifsc = null


                }
                // else if(this.ifsc.length < 4){

                // }
                ////console.log("IFSC :", ifsvc);
                const axios = require('axios');
                var config = {
                    method: 'get',
                    url: 'https://ifsc.razorpay.com/' + ifsvc,
                };
                var axiosThis = this
                await axios(config)
                    .then(function (response) {
                        //////console.log(JSON.stringify(response.data));
                        var req_data = response.data;
                        var bank_name = req_data['BANK'];
                        var branch = req_data['BRANCH'];
                        var micr = req_data['MICR'];
                        ////console.log(bank_name, branch, micr)
                        axiosThis.bank_name = bank_name;
                        axiosThis.bank_branch = branch;
                        axiosThis.bank_micr = micr;
                        axiosThis.ifscErrorDesc = false
                    })
                    .catch(function (error) {
                        //console.log("IFSC errorr catched!")
                        console.log(error)
                        axiosThis.Rulesforifsc = "Invalid IFSC"
                        axiosThis.ifscErrorDesc = true
                    });
            }
            else {
                this.bank_name = null;
                this.bank_branch = null;
                this.bank_micr = null;
            }
        },
        // bank api
        bankDetailUpdate() {
            //console.log(this.ifsc)
            //console.log(this.accNo)
            //console.log(this.accountType)
            //console.log(this.bank_name)

            // this.$v.$touch()
            // alert("Bank page enter")
            this.$refs.bankform.validate()
            // this.ash = true
            // ////console.log("adasdasdasdsa=",this.ifsc ,this.accNo , this.bank_name)
            if (this.bankchange) {
                //console.log("inside change api");
                if (this.ifsc && this.accNo && this.bank_name && this.accountType) {
                    this.cardloader = true;
                    this.btndis = true;
                    // this.$v.$reset()
                    var config = {
                        method: 'post',
                        url: api.api_url + '/bankDetails?mob=' + atob(localStorage.getItem('hash')) + '&bankName=' + this.bank_name + '&ifsc=' + this.ifsc + '&accountNo=' + this.accNo + '&micr=' + this.bank_micr + '&branch=' + this.bank_branch + '&bank_account_type=' + this.accountType + '&change=' + 'change',
                        headers: {}
                    };
                    var axiosThis = this
                    axios(config)
                        .then(function (response) {
                            ////console.log("Main response == ", (response.data));
                            var resdata = response.data.data
                            // var  resdata = JSON.parse(man);
                            //console.log("resdata", resdata)
                            axiosThis.bankchange = false

                            if (resdata["status"] == "completed") {
                                ////console.log("Main response == 222", (response.dat a));

                                axiosThis.cardloader = false
                                axiosThis.btndis = false

                                axiosThis.bankUname = resdata["results"]["registered_name"]
                                axiosThis.errorMessage = "Verified successfully"
                                axiosThis.accstatus = resdata["results"]["account_status"]
                                axiosThis.tran_no = resdata["utr"]
                                axiosThis.accNo = resdata["fund_account"]["bank_account"]["account_number"]
                                axiosThis.ifsc = resdata["fund_account"]["bank_account"]["ifsc"]
                                axiosThis.bankverifystatus = true
                                if (axiosThis.accstatus != 'active') {
                                    var localHash = localStorage.getItem('hash')
                                    var retryCountFromLocal = JSON.parse(localStorage.getItem(localHash))
                                    axiosThis.retryCount = retryCountFromLocal
                                    // ////console.warn("kkkkkk",localHash,retryCountFromLocal,axiosThis.retryCount)
                                    axiosThis.retryCount += 1;
                                    localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                                    // ////console.log("----------",axiosThis.retryCount)
                                    axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time"
                                    // ////console.log("if 2",axiosThis.retryCount)
                                    if (axiosThis.retryCount >= 3) {
                                        axiosThis.retrybank = false
                                        axiosThis.bankverifystatus = true
                                        axiosThis.retrybankacc = false
                                        axiosThis.retryCount = 3;
                                        localStorage.setItem(localHash, "3");
                                        // axiosThis.maxtime
                                        //axiosThis.checkforbank()
                                        // axiosThis.bankverifystatusaftRetry = true
                                        axiosThis.errorMessage = "We are unable to validate your banking information. kindly provide accurate bank information when submitting documents, your information will be verified by our team"
                                        // axiosThis.retrybank=false;
                                    } else {
                                        axiosThis.retrybank = true;
                                        axiosThis.bankverifystatus = true;
                                    }

                                    //axiosThis.checkforbank()
                                }
                                // var data = response.data
                                // axiosThis.ifsc = data['ifsc']
                                // axiosThis.bank_name = data['bankName']
                                // axiosThis.accNo = data['accNo']
                                // axiosThis.bank_micr = data['micr']
                                // axiosThis.bank_branch = data['branch']
                                // axiosThis.accountType = data['bank_account_type']

                            }

                            else {
                                axiosThis.accstatus = resdata["results"]["account_status"]
                                axiosThis.tran_no = resdata["utr"]

                                // if(axiosThis.accstatus!='active'){
                                localHash = localStorage.getItem('hash')
                                retryCountFromLocal = JSON.parse(localStorage.getItem(localHash))
                                axiosThis.retryCount = retryCountFromLocal
                                // ////console.warn("kkkkkk",localHash,retryCountFromLocal,axiosThis.retryCount)
                                axiosThis.retryCount += 1;
                                localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                                // ////console.log("----------",axiosThis.retryCount)
                                axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time"
                                // ////console.log("if 2",axiosThis.retryCount)
                                if (axiosThis.retryCount >= 3) {
                                    axiosThis.retrybank = false
                                    axiosThis.bankverifystatus = true
                                    axiosThis.retrybankacc = false
                                    axiosThis.retryCount = 3;
                                    localStorage.setItem(localHash, "3");
                                    // axiosThis.maxtime
                                    //axiosThis.checkforbank()
                                    // axiosThis.bankverifystatusaftRetry = true
                                    axiosThis.errorMessage = "We are unable to validate your banking information. kindly provide accurate bank information when submitting documents, your information will be verified by our team"
                                    // axiosThis.retrybank=false;
                                } else {
                                    axiosThis.retrybank = true;
                                    axiosThis.bankverifystatus = true;
                                }
                                //axiosThis.checkforbank()
                                // }
                                axiosThis.cardloader = false
                                axiosThis.btndis = false
                                //  axiosThis.bankverifystatus=true
                                //  axiosThis.retrybank=true;
                                //axiosThis.checkforbank()
                            }
                            // axiosThis.$router.push({name:'exchange'})    
                        })
                        .catch(function (error) {
                            console.log(error);
                            // axiosThis.toasttext = 'Please try again';
                            // axiosThis.success = null;
                            // axiosThis.failure = null;
                            // axiosThis.snackbar = true;
                        });
                } else {
                    axiosThis.toasttext = 'Some requirement is Missing';
                    axiosThis.success = false;
                    axiosThis.failure = true;
                    axiosThis.snackbar = true;
                }
            }
            else {
                //console.log(this.ifsc)
                //console.log(this.accNo)
                //console.log(this.accountType)
                //console.log(this.bank_name)
                if (this.ifsc.length == 11 && this.accNo && this.bank_name && this.accountType) {
                    this.cardloader = true;
                    // this.$v.$reset()
                    //console.log("in in in in in in in in in in")
                    this.btndis = true

                    var config2 = {
                        method: 'post',
                        url: api.api_url + '/bankDetails?mob=' + atob(localStorage.getItem('hash')) + '&bankName=' + this.bank_name + '&ifsc=' + this.ifsc + '&accountNo=' + this.accNo + '&micr=' + this.bank_micr + '&branch=' + this.bank_branch + '&bank_account_type=' + this.accountType,
                        headers: {}
                    };
                    const axiosThis = this

                    axios(config2)
                        .then(function (response) {
                            ////console.log("Main response == ", (response.data));
                            var resdata = response.data;
                            if (resdata["data"]["status"] == "completed") {
                                ////console.log("Main response == 222", (response.data));
                                axiosThis.cardloader = false
                                axiosThis.btndis = false
                                axiosThis.bankUname = resdata['data']["results"]["registered_name"]
                                axiosThis.errorMessage = "Verified successfully"
                                axiosThis.accstatus = resdata['data']["results"]["account_status"]
                                axiosThis.tran_no = resdata['data']["utr"]
                                axiosThis.accNo = resdata['data']["fund_account"]["bank_account"]["account_number"]
                                axiosThis.ifsc = resdata['data']["fund_account"]["bank_account"]["ifsc"]
                                axiosThis.bankverifystatus = true
                                if (axiosThis.accstatus != 'active') {
                                    var localHash = localStorage.getItem('hash')
                                    var retryCountFromLocal = JSON.parse(localStorage.getItem(localHash))
                                    axiosThis.retryCount = retryCountFromLocal
                                    // ////console.warn("kkkkkk",localHash,retryCountFromLocal,axiosThis.retryCount)
                                    axiosThis.retryCount += 1;
                                    localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                                    // ////console.log("----------",axiosThis.retryCount)
                                    axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time"
                                    // ////console.log("if 2",axiosThis.retryCount)
                                    if (axiosThis.retryCount >= 3) {
                                        axiosThis.retrybank = false
                                        axiosThis.bankverifystatus = true
                                        axiosThis.retrybankacc = false
                                        axiosThis.retryCount = 3;
                                        localStorage.setItem(localHash, "3");
                                        // axiosThis.maxtime
                                        //axiosThis.checkforbank()
                                        // axiosThis.bankverifystatusaftRetry = true
                                        axiosThis.errorMessage = "We are unable to validate your banking information. kindly provide accurate bank information when submitting documents, your information will be verified by our team"
                                        // axiosThis.retrybank=false;
                                    } else {
                                        axiosThis.retrybank = true;
                                        axiosThis.bankverifystatus = true;
                                    }

                                    //axiosThis.checkforbank()
                                }
                                // var data = response.data
                                // axiosThis.ifsc = data['ifsc']
                                // axiosThis.bank_name = data['bankName']
                                // axiosThis.accNo = data['accNo']
                                // axiosThis.bank_micr = data['micr']
                                // axiosThis.bank_branch = data['branch']
                                // axiosThis.accountType = data['bank_account_type']

                            }

                            else {
                                axiosThis.accstatus = resdata['data']["results"]["account_status"]
                                axiosThis.tran_no = resdata['data']["utr"]

                                // if(axiosThis.accstatus!='active'){
                                localHash = localStorage.getItem('hash')
                                retryCountFromLocal = JSON.parse(localStorage.getItem(localHash))
                                axiosThis.retryCount = retryCountFromLocal
                                // ////console.warn("kkkkkk",localHash,retryCountFromLocal,axiosThis.retryCount)
                                axiosThis.retryCount += 1;
                                localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                                // ////console.log("----------",axiosThis.retryCount)
                                axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time"
                                // ////console.log("if 2",axiosThis.retryCount)
                                if (axiosThis.retryCount >= 3) {
                                    axiosThis.retrybank = false
                                    axiosThis.bankverifystatus = true
                                    axiosThis.retrybankacc = false
                                    axiosThis.retryCount = 3;
                                    localStorage.setItem(localHash, "3");
                                    // axiosThis.maxtime
                                    //axiosThis.checkforbank()
                                    // axiosThis.bankverifystatusaftRetry = true
                                    axiosThis.errorMessage = "We are unable to validate your banking information. kindly provide accurate bank information when submitting documents, your information will be verified by our team"
                                    // axiosThis.retrybank=false;
                                } else {
                                    axiosThis.retrybank = true;
                                    axiosThis.bankverifystatus = true;
                                }
                                //axiosThis.checkforbank()
                                // }
                                axiosThis.cardloader = false
                                axiosThis.btndis = false
                                //  axiosThis.bankverifystatus=true
                                //  axiosThis.retrybank=true;
                                //axiosThis.checkforbank()
                            }

                            // axiosThis.$router.push({name:'exchange'})    
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    axiosThis.snackbar = true;
                    //console.log("data error", axiosThis.snackbar);

                    axiosThis.toasttext = 'Some requirement is Missing';
                    axiosThis.success = false;
                    axiosThis.failure = true;
                }
            }
        },
        handleKeyUp(event) {

            if (this.ifsc&& this.accNo && this.bank_name && this.accountType && this.bankverifystatus == false) {
                // if (this.otpdisp && !this.emailDisp  && !this.isShowing && !this.emailOtpDisp  ) {
                if (event.key === 'Enter') {
                    // alert("fghj")
                    this.bankDetailUpdate();
                    ////console.log("verify mobile Enter pressed");

                    // this.otpVerify();
                    // this.verifyEmail();
                }

                // }
            }else if(this.bankverifystatus == true){
                if (event.key === 'Enter') {
                    // alert("fghj")
                    this.checkforbank();
                    ////console.log("verify mobile Enter pressed");

                    // this.otpVerify();
                    // this.verifyEmail();
                }
            }
        },

        // find ifsc


    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    },
    watch: {
        loader() {
            if (this.mobile_num) {
                if (this.mobile_num.length == 10) {
                    //this.errorMobNumber=false
                    const l = this.loader
                    this[l] = !this[l]

                    setTimeout(() => (this[l] = false), 2000)

                    this.loader = null
                    if (this.emailDisp != true) {
                        this.loadertext = "OTP Sent"
                        this.$v.$reset();
                        setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
                    }
                }
            }

        },
        panLoader() {
            const l = this.panLoader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 2000)

            this.panLoader = null
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        mountcheck() {

        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value == 0) {
                    this.timerEnabled = false
                    this.resendText = true
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    computed: {

        //  otpErrors() {
        //     // const errors = []
        //     ////console.log("OTP Valid : ", this.errorOTPDisp, this.otp)
        //     if (this.errorOTPDisp) {
        //         this.toasttext = 'OTP is invalid'
        //       // errors.push('Enter OTP is invalid')
        //       return errors
        //     }
        //     else {
        //       ////console.log(this.$v.otp.$dirty, this.$v.otp.required);
        //       if (!this.$v.otp.$dirty) return errors
        //       !this.$v.otp.required && errors.push('OTP is required')
        //       return errors
        //     }

        //   },
    },
    mounted() {
        // if (this.$route.query.id) {
        //     localStorage.setItem('nalp', this.$route.query.id)
        //     //console.log("HAHHAHAHAHAHA",this.$route.query.id);
        //   }
        // this.accountType = sessionStorage.getItem('accType')
        // this.ifsc = sessionStorage.getItem('ifsc')
        // this.accNo = sessionStorage.getItem('accno')
        // //console.log("6666", this.accountType,this.ifsc,this.accNo);
        // this.mainurl = api.api_url;
        var mob = localStorage.getItem('hash')
        // console.log(mob);
        
        if (mob == null) {
            this.$router.push("/");

        }
        document.addEventListener('keyup', this.handleKeyUp);

        var config = {
            method: 'post',
            url: api.api_url + '/bankDetails?mob=' + atob(localStorage.getItem('hash')),
            headers: {}
        };
        var axiosThis = this
        axios(config)
            .then(function (response) {
                ////console.log("Main response == ", (response.data));
                var resdata = response.data.data
                // var  resdata = JSON.parse(man);
                // //console.log("Main response == 222", (man));
                //console.log("Main response == 222", resdata);

                // var  resdata = response.data;
                if (resdata["status"] == "completed") {

                    axiosThis.cardloader = false
                    axiosThis.btndis = false
                    axiosThis.bankUname = resdata["results"]["registered_name"]
                    axiosThis.errorMessage = "Verified successfully"
                    axiosThis.accstatus = resdata["results"]["account_status"]
                    axiosThis.tran_no = resdata["utr"]
                    axiosThis.accNo = resdata["fund_account"]["bank_account"]["account_number"]
                    axiosThis.ifsc = resdata["fund_account"]["bank_account"]["ifsc"]
                    axiosThis.bankverifystatus = true
                    if (axiosThis.accstatus != 'active') {
                        var localHash = localStorage.getItem('hash')
                        var retryCountFromLocal = JSON.parse(localStorage.getItem(localHash))
                        axiosThis.retryCount = retryCountFromLocal
                        // ////console.warn("kkkkkk",localHash,retryCountFromLocal,axiosThis.retryCount)
                        axiosThis.retryCount += 1;
                        localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                        // ////console.log("----------",axiosThis.retryCount)
                        axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time"
                        // ////console.log("if 2",axiosThis.retryCount)
                        if (axiosThis.retryCount >= 3) {
                            axiosThis.retrybank = false
                            axiosThis.bankverifystatus = true
                            axiosThis.retrybankacc = false
                            axiosThis.retryCount = 3;
                            localStorage.setItem(localHash, "3");
                            // axiosThis.maxtime
                            //axiosThis.checkforbank()
                            // axiosThis.bankverifystatusaftRetry = true
                            axiosThis.errorMessage = "We are unable to validate your banking information. kindly provide accurate bank information when submitting documents, your information will be verified by our team"
                            // axiosThis.retrybank=false;
                        } else {
                            axiosThis.retrybank = true;
                            axiosThis.bankverifystatus = true;
                        }

                        //axiosThis.checkforbank()
                    }
                    // var data = response.data
                    // axiosThis.ifsc = data['ifsc']
                    // axiosThis.bank_name = data['bankName']
                    // axiosThis.accNo = data['accNo']
                    // axiosThis.bank_micr = data['micr']
                    // axiosThis.bank_branch = data['branch']
                    // axiosThis.accountType = data['bank_account_type']

                }

                else {
                    axiosThis.accstatus = resdata["results"]["account_status"]
                    axiosThis.tran_no = resdata["utr"]

                    // if(axiosThis.accstatus!='active'){
                    localHash = localStorage.getItem('hash')
                    retryCountFromLocal = JSON.parse(localStorage.getItem(localHash))
                    axiosThis.retryCount = retryCountFromLocal
                    // ////console.warn("kkkkkk",localHash,retryCountFromLocal,axiosThis.retryCount)
                    axiosThis.retryCount += 1;
                    localStorage.setItem(localHash, JSON.stringify(axiosThis.retryCount));
                    // ////console.log("----------",axiosThis.retryCount)
                    axiosThis.errorMessage = "Currently we are unable to retrive your bank information, kindly try after some time"
                    // ////console.log("if 2",axiosThis.retryCount)
                    if (axiosThis.retryCount >= 3) {
                        axiosThis.retrybank = false
                        axiosThis.bankverifystatus = true
                        axiosThis.retrybankacc = false
                        axiosThis.retryCount = 3;
                        localStorage.setItem(localHash, "3");
                        // axiosThis.maxtime
                        //axiosThis.checkforbank()
                        // axiosThis.bankverifystatusaftRetry = true
                        axiosThis.errorMessage = "We are unable to validate your banking information. kindly provide accurate bank information when submitting documents, your information will be verified by our team"
                        // axiosThis.retrybank=false;
                    } else {
                        axiosThis.retrybank = true;
                        axiosThis.bankverifystatus = true;
                    }
                    //axiosThis.checkforbank()
                    // }
                    axiosThis.cardloader = false
                    axiosThis.btndis = false
                    //  axiosThis.bankverifystatus=true
                    //  axiosThis.retrybank=true;
                    //axiosThis.checkforbank()
                }

                // axiosThis.$router.push({name:'exchange'})    
            })
            .catch(function (error) {
                console.log(error);
            });
        // this.bankverifystatus = true
    },
    created() {
        this.startAutoChange; 
        let stages = Number(localStorage.getItem("stageis"));
        if (typeof stages === 'number') {
            for (let s = 0; s < this.stagesitemsis.length; s++) {
                this.stagesitemsis[s]['disable'] = this.stagesitemsis[s].key > stages ? true : false;
                // //console.log(this.stagesitemsis[s]['disable'],"this.stagesitemsis[s]['disable']")
            }
            if (stages < this.stageis) {
                this.stageis = stages;
                this.stageToo();
            }
        }

        else {
            this.$router.push("/");
        }
        this.startAutoChange();
        const thresholdResolution = 767;

        const screenWidth = window.innerWidth;

        if (screenWidth < thresholdResolution) {
            this.isLesserThanResolution = true;
        } else {
            this.isLesserThanResolution = false;

        }

    },
}

</script>

<style lang="scss">
.card-sty1 {
    /* border: 1px solid #F2F2F2 !important; */
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    /* margin-left: -1px !important; */
    /* margin-right: 2px !important; */
}

.body-card {
    height: 100vh !important;


    /* overflow-y: scroll !important; */
}



// ::-webkit-scrollbar {
//     display: none;
// }

.loader {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    width: 100%;

}

.blocktext {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto !important;
    margin-bottom: auto !important;
}


/* Media Query for Mobile Devices */
//         @media (max-width: 480px) {
//             .scrollbar{
// //   height: 440px;
//   width: 100%;
// }
//         }

//         /* Media Query for low resolution  Tablets, Ipads */
//         @media (min-width: 481px) and (max-width: 767px) {
//             .scrollbar{
// //   height: 440px;
//   width: 100%;overflow: scroll;
// }
//         }

//         /* Media Query for Tablets Ipads portrait mode */
//         @media (min-width: 768px) and (max-width: 1024px){
//             .scrollbar{
// //   height: 440px;
//   width: 100%;overflow: scroll;
// }
//         }

//         /* Media Query for Laptops and Desktops */
//         @media (min-width: 1025px) and (max-width: 1280px){
//             .scrollbar{
// //   height: 440px;
//   width: 100%;overflow: scroll;
// }
//         }

//         /* Media Query for Large screens */
//         @media (min-width: 1281px) {
//             .scrollbar{
//   height: 440px;
//   width: 100%;
//   overflow: scroll;
// }
//         }

// .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
//     padding: 0 12px;
//     width: 170px;
// }
</style>

