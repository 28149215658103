<template class="">
    <div class="pa-0" style="overflow-y: hidden;  overflow: scroll; ;">

        <v-container  class="body-card " style="">


            <v-card width="" height="" class="card-sty1 ">
                <v-progress-linear absolute color='#0037B7' height="12" value="88"></v-progress-linear>
                <v-toolbar color="transparent" dense flat class="mt-5 mb-3" style="overflow-y: hidden;">
                    <v-row no-gutters>
                        <v-col class="">
                            <img :src="require('@/assets/Zebu Logo.svg')" class="ml-1" contain width="90px">

                        </v-col>
                        <v-col class="">

                        </v-col>
                        <v-col class="stageselect" >
                            <!-- <v-select outlined style="" dense @change="stageToo()" item-text="val" item-value="key"
                                :items="stagesitemsis" v-model="stageis" flat solo background-color="#ffffff"
                                class="txtfield elevation-0 rounded-pill mr-4  mt-3 " label="Stage" hide-details
                                append-icon="mdi-chevron-down"></v-select> -->
                                
                        </v-col>
                    </v-row>
                </v-toolbar>
                <v-row class="px-8">
                    <v-col lg="6" class="d-none d-lg-block">
                        <v-card width="100%" class="rounded-0  elevation-0">
                            <img v-for="(image, index) in images" :key="index" :src="image"
                                :style="{ display: index === currentIndex ? 'block' : 'none' }"
                                style="height: 26em; display: flex; align-items: center; justify-content: center"
                                width="100%" /> </v-card>
                    </v-col>
                    <v-col lg="6" class="pa-0 cols-sm-12">
                        <v-card width="100%" style="" class="rounded-0 elevation-0">
                            <p class="subtitle-1  font-weight-bold mb-4">Verify data accuracy.</p>
                            <v-card class="elevation-0  scrollhighlight"  
                                style=" overflow: scroll; width: 100% ;overflow-x: hidden; /* Hide horizontal scrollbar */">
                                <div v-for="(item, index) in DisplayData" :key="index"
                                    style="display: inline-flex; overflow: hidden;" class="flex-column ">
                                    <!-- <h2 class="text-center py-4">Finalised Details</h2> -->

                                    <div class="d-flex flex-row ">
                                        <p class="body-1  font-weight-bold mb-4">Client Details</p>
                                        <v-btn class="ml-auto mr-2" @click="editpan" :ripple="false" icon text plain><v-icon
                                                size="17"> mdi
                                                mdi-pencil</v-icon></v-btn>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col cols="6">
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.clientid" readonly
                                                    label="CLIENT ID"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.Name" readonly label="NAME"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.PAN" readonly label="PAN"></v-text-field>
                                            </v-col>
                                            <v-col>

                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="d-flex flex-row ">
                                        <p class="body-1  font-weight-bold mb-4">Aadhar Details</p>
                                        <v-btn class="ml-auto mr-2" @click="editaadhar" :ripple="false" icon text
                                            plain><v-icon size="17"> mdi
                                                mdi-pencil</v-icon></v-btn>
                                    </div>

                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.aadharNo" readonly
                                                    label="AADHAR NO"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_fathername" readonly
                                                    label="FATHER NAME"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_dob" readonly label="DOB"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_gender" readonly
                                                    label="GENDER"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row px-4  mt-1 mb-1">

                                            <v-textarea class="area-field" readonly rounded outlined label="ADDRESS"
                                                v-model="item.adhar_address"></v-textarea>

                                        </v-row>

                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_dist" readonly
                                                    label="DISTRICT"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_pincode" readonly
                                                    label="PINCODE"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">

                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_state" readonly
                                                    label="STATE"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.adhar_country" readonly
                                                    label="COUNTRY"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="d-flex flex-row ">
                                        <p class="body-1  font-weight-bold mb-4">Bank Details</p>
                                        <v-btn class="ml-auto mr-2" @click="Editbank" :ripple="false" icon text
                                            plain><v-icon size="17"> mdi
                                                mdi-pencil</v-icon></v-btn>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.accNo" readonly label="ACC NO"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.AddressLine1" readonly
                                                    label="ACC TYPE"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.ifsc" readonly label="IFSC CODE"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-if="item.peenydropVerify == 'yes'" width="" dense outlined
                                                    rounded flat class="mr-4 inputPrice" type="" value="Verified" readonly
                                                    label="PENNY DROP "></v-text-field>
                                                <v-text-field v-if="item.peenydropVerify == 'No'" width="" dense outlined
                                                    rounded flat class="mr-4 inputPrice" type="" value="Not Verified"
                                                    readonly label="PENNY DROP "></v-text-field>

                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div class="d-flex flex-row ">
                                        <p class="body-1  font-weight-bold mb-4">Segment Details</p>
                                        <v-btn class="ml-auto mr-2" @click="EditSegment" :ripple="false" icon text
                                            plain><v-icon size="17"> mdi
                                                mdi-pencil</v-icon></v-btn>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" :value="exchangeSelect" readonly label="SEGMENT"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.qualification" readonly
                                                    label="QUALIFICATION"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" :value="item.tradeExpYear" readonly
                                                    label="TRADE EXP"></v-text-field>
                                            </v-col>
                                            <v-col>

                                            </v-col>
                                        </v-row>
                                    </div>
                                    <p class="body-1  font-weight-bold mb-4">Branch Details</p>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.zebu_branch_code" readonly
                                                    label="BRANCH CODE"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.zebu_manager_name" readonly
                                                    label="MANAGER NAME"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row class="d-flex flex-row ">
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.zebu_manager_contact" readonly
                                                    label="MANAGER CONTACT NO"></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field width="" dense outlined rounded flat class="mr-4 inputPrice"
                                                    type="" v-model="item.zebu_manager_mail" readonly
                                                    label="MANAGER MAIL"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <p class="body-1  font-weight-bold mb-4">Aadhar Image</p>
                                    <img :src="aadhaApi" height="600" width="100%" class="mb-4" />
                                    <p class="body-1  font-weight-bold mb-4">Pan Image</p>
                                    <img :src="panApi" class="mb-4" height="550" width="100%" />
                                    <div v-if="pennyVerify != 'yes'">
                                        <!-- <div> -->
                                        <p class="body-1  font-weight-bold mb-4">Bank Image</p>
                                        <img :src="bankApi" height="500" width="100%" />
                                    </div>
                                    <div v-if="acctype == 'AP'">
                                        <!-- <div> -->
                                        <p class="body-1  font-weight-bold mb-4">Education Image</p>
                                        <img :src="eduApi" height="500" width="100%" />
                                    </div>
                                    <div class="pa- text-center mb-16">


                                        <v-checkbox class="lable" color="black" v-model="checkboxEsign">
                                            <template v-slot:label>
                                                <div style="text-align:justify;" class="mr-8">
                                                    I confirm to have read and understood the contents of the Annexure
                                                    documents &amp; Standing
                                                    Instruction
                                                </div>
                                            </template>
                                        </v-checkbox>
                                        <v-btn color="black" class="fltbtn otpbtn py-6 my-4 white--text" style="width: 93%;"     rounded @click="digioEsign()"
                                            v-if="checkboxEsign">
                                            Aadhar E-sign
                                        </v-btn>
                                        <v-btn color="black" class="fltbtn otpbtn py-6 my-4 white--text" style="width: 93%;"     rounded v-if="!checkboxEsign" disabled>
                                            Aadhar E-sign
                                        </v-btn>

                                        
                                    </div>
                                </div>
                            </v-card>
                            <!-- <v-textarea class="" rounded outlined row-height="100" label="ADDRESS"
                                                v-model="item.adhar_address"></v-textarea> -->
                        </v-card>

                    </v-col>
                </v-row>
            </v-card>

        </v-container>
    </div>
</template>
<script>
history.pushState(null, null, location.href);
window.onpopstate = function () {
    history.go(1);
};
</script>
<script src="https://app.digio.in/sdk/v8/digio.js"></script>
<script>
import axios from 'axios';
import api from '@/api';

import imagePath  from '../assets/inv1.svg'
import imagePath1  from '../assets/inv2.svg'
import imagePath2  from '../assets/inv3.svg'
import imagePath3  from '../assets/inv4.svg'
import imagePath4  from '../assets/inv5.svg'
const script = document.createElement('script')
script.src = 'https://app.digio.in/sdk/v8/digio.js'
document.body.appendChild(script)
export default {
    data: () => ({
        // Sign Up
        mobile_num: null,
        otp: '',
        stageis: 7,
        stagesitemsis: [{ key: 1, val: 'Sign up' }, { key: 2, val: 'Identity verification' }, { key: 3, val: 'Digilocker' }, { key: 4, val: 'Bank details' }, { key: 5, val: "Segment selection" }, { key: 6, val: 'Document upload' }, { key: 7, val: 'e-sign' }, ],
       
        otpdisp: true,
        snackbar: false,
        timerEnabled: false,
        timerCount: 120,
        resendText: false,
        toasttext: 'null',
        cardloader: false,
        errorOTPDisp: false,
        numberStart: true,
        otpstart: true,
        otptext: '',
        // test\
        mainurl: null,

        accountTypeItems: ['Saving Account', 'Current Account'],
        accountType: null,
        accNo: null,
        ifsc: null,
        retrybank: false,

        retrybankacc: false,
        retryCount: 0,
        fileattach1st: false,
        opencam: false,
        // cam
        isCameraOpen: false,
        isPhotoTaken: false,
        isShotPhoto: false,
        isLoading: false,
        link: '#',
        photoData: null,
        localHash: null,
        dialog1: false,
        panStatus: null,
        pennyVerify: '',
        acctype: null,
        eduFile: false,
        DisplayData: [],
        aadhaApi: '',
        fatherNameval: '',
        corFather: '',
        bankApi: '',
        Slidervalue: 1,
        eduApi: '',
        // fatherNameval : '',
        // from kyc
        loader: null,
        panLoader: null,
        loading: false,
        // mobile_num: null,
        //errorMobNumber:false,
        logstatus: false,
        reference: null,
        // timerCount: 120,
        // timerEnabled: false,
        // resendText: false,
        email: null,
        emailDisp: false,
        emailOtpDisp: false,
        otpemail: null,
        // otp: null,
        checkbox: false,
        invalid: null,
        text: `You will receive an OTP on your number`,
        loadertext: "Verify",
        timeout: 1000,
        // otpdisp: true,
        mobdisp: true,
        isShowing: false,
        activePicker: null,
        date: null,
        menu: false,
        panDetaildisp: true,
        panFetchDetail: false,
        panInput: null,
        // accNo:s null,
        // ifsc: null,
        bank_name: null,
        bank_branch: null,
        bank_micr: null,
        enabledRegisteredAP: false,
        regMemberName: null,
        exchangeSelect: [],
        tradingExperience: null,
        qualificationList: ['Highschool', 'Graduation', 'Post Graduation', 'Doctrate', 'Prof. Degree', 'Under High School', 'Illiterate-Other'],
        occupationList: ['Private Sector', 'Public Sector', 'Government Service', 'Business', 'Professional Agriculturist', 'Retired', 'Housewife', 'Student', 'Forex Dealer', 'Other'],
        qualification: null,
        occupation: null,
        errorDescPAN: false,
        KycFetchDisp: false,
        dob: null,
        aadharNo: null,
        add1: null,
        add2: null,
        add3: null,
        add4: null,
        address: null,
        country: null,
        district: null,
        state: null,
        pinCode: null,
        fatherName: null,
        gender: null,
        name: null,
        panFile: null,
        // aadharFile:null,
        bankFile: null,
        educationFiler: null,
        kycLoader: false,
        checkboxEsign: false,
        digioFile: null,
        digioMail: null,
        ekycStatus: false,
        step: 1,
        bankverifystatus: null,

        digiInformationShow: false,
        dialog: false,
        isDarkModeEnabled: false,
        // errorOTPDisp: false,
        erroreOTPDisp: false,
        // cardloader: false,
        ifscErrorDesc: false,
        // snackbar: false,
        // toasttext: 'null',
        // timeout: 2000,
        panInputLength: false,
        success: false,
        failure: false,
        panApi : '',
        images: [
      // 'http://zebu-ekcy.web.app/img/inv3.bf76f1c4.svg',
      // 'http://zebu-ekcy.web.app/img/inv5.fb7c3474.svg',
      // 'http://zebu-ekcy.web.app/img/trd3.2bc52f23.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      // 'http://zebu-ekcy.web.app/img/inv4.6e5ffeda.svg',
      imagePath,imagePath1,imagePath2,imagePath3,imagePath4
      // 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',

      // 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',

    ],

    currentIndex: 0,
    }),
    created() {
    this.startAutoChange();
    },
    methods: {
        startAutoChange() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % 4; // Increment index and loop back to 0
      }, 3000); // Change every 3 seconds (3000 milliseconds)
    },
        stageToo() {
            if (this.stageis == 1) {
                this.$router.push("/");
            }
            else if (this.stageis == 2) {
                this.$router.push("/pan");
            }
            else if (this.stageis == 3) {
                this.$router.push("/aadhar");
            }
            else if (this.stageis == 4) {
                this.$router.push("/bank");
            }
            else if (this.stageis == 5) {
                this.$router.push("/segments");
            }
            else if (this.stageis == 6) {
                this.$router.push("/fileupload");
            }
            else if (this.stageis == 7) {
                this.$router.push("/preview");
            }
            // else if (this.stageis == 8) {
            //     this.$router.push("");
            // }
        },
        digioEsign() {
            // var http = require('http');
            // var fs = require('fs');
            // var google = http.createClient(80, 'www.google.com');
            // var request = google.request('GET', '/sdk/v8/digio.js',
            //   {'host': 'app.digio.in'});
            // request.end();
            // out = fs.createWriteStream('digio.js');
            // request.on('response', function (response) {
            //   response.setEncoding('utf8');
            //   response.on('data', function (chunk) {
            //     out.write(chunk);
            //   });
            // });
            //var Digio = require('https://app.digio.in/sdk/v8/digio.js');
            ////console.log('File ID : ' + this.digioFile)
            // localStorage.setItem('fileid', this.digioFile)
            var options = {
                environment: "production",
                "callback": function (t) {
                    //console.log("ttttttt :: ", t);
                    if (t.hasOwnProperty('error_code')) {
                        // document.getElementById("loading").style.display='none';
                        // document.getElementById("result").innerHTML="failed to sign with error : "+t.message;
                        ////console.log('Error Digio')
                        ////console.log(t.message)
                    }
                    else {
                        //document.getElementById("result").innerHTML="API Mandate Authentication Successful"
                        //successDigio();
                        ////console.log('Success Digio');
                        ////console.log(this.step, this.ekycStatus);
                        window.location.href = "?data=" + JSON.stringify(t.message);
                    }
                }
            };

            var digio = new Digio(options);
            digio.init(); // Call init on user action (eg.button press and proceed to asynchronous upload)
            this.digioMail = localStorage.getItem('mailid')
            this.digioFile = localStorage.getItem('fileid')
            digio.submit(this.digioFile, this.digioMail,);
        },
        EditSegment() {
            // this.exchangeSelect = '';
            // this.tradingExperience = '';
            // this.qualification = '';
            // this.occupation = '';
            // this.step = 5;
            //this.Slidervalue = 60
            sessionStorage.setItem('segment', false );

            this.$router.push("/segments");


        },
        editaadhar(){
            this.$router.push("/aadhar");
        },
        Editbank(){
            this.$router.push("/bank");

        },
        editpan(){
            sessionStorage.setItem('snackbar', false );
            // sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);
            // sessionStorage.setItem('aadhaApi', axiosThis.aadhaApi);

            this.$router.push("/pan");

        }



    },
    watch: {
        loader() {
            if (this.mobile_num) {
                if (this.mobile_num.length == 10) {
                    //this.errorMobNumber=false
                    const l = this.loader
                    this[l] = !this[l]

                    setTimeout(() => (this[l] = false), 2000)

                    this.loader = null
                    if (this.emailDisp != true) {
                        this.loadertext = "OTP Sent"
                        this.$v.$reset();
                        setTimeout(() => (this.mobdisp = false, this.otpdisp = false, this.timerEnabled = true), 1000)
                    }
                }
            }

        },
        panLoader() {
            const l = this.panLoader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 2000)

            this.panLoader = null
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                if (value == 0) {
                    this.timerEnabled = false
                    this.resendText = true
                }

            },
            immediate: true // This ensures the watcher is triggered upon creation
        }

    },
    computed: {
    },
    mounted() {
        var mob = localStorage.getItem('hash')
        // console.log(mob);
        
        if (mob == null) {
            this.$router.push("/");

        }
        this.aadhaApi = sessionStorage.getItem('aadhaApi')
        // console.log("this.aadhaApi",this.aadhaApi);

        this.panApi = sessionStorage.getItem('panApi')
        //console.log("this.panApi",this.panApi);

        this.bankApi = sessionStorage.getItem('bankApi')
        //console.log("this.bankApi",this.bankApi);

        this.pennyVerify = "No"
        //console.log("this.bankApi",this.pennyVerify);

        this.eduApi = sessionStorage.getItem('eduApi')
        // console.log("this.eduApi",this.eduApi);

        this.acctype = sessionStorage.getItem('acctype')

        this.mainurl = api.api_url;
        this.DisplayData = JSON.parse(sessionStorage.getItem('DisplayData'));
        //console.log("this.DisplayData", this.DisplayData);
        var data1 = this.DisplayData[0].BSEseg
        var data2 = this.DisplayData[0].MCXseg
        var data3 = this.DisplayData[0].NSEseg
        if (data1 != '') {
            this.exchangeSelect.push(data1)
            
        }
        if (data2 != '') {
        this.exchangeSelect.push( data2)
            
        }
        if (data3 != '') {
        this.exchangeSelect.push( data3)
            
        }
        //console.log("datadata",data1);
        //console.log("datadata",data2);
        //console.log("datadata",data3);

        document.addEventListener('keyup', this.handleKeyUp);
        //console.log(this.$route.query)
        if (this.$route.query.stageid == 4) {
            // this.step = 2
            // this.Slidervalue = 34
            this.$router.push("/pan");

            this.$route.query.stageid == 0
        }
        // var dataFromDigi = this.$route.query.code;

        var plan = this.$route.query.id;
        //console.log(plan)
        if (this.$route.query.id) {
            localStorage.setItem('nalp', this.$route.query.id)
        }
        var data = sessionStorage.getItem('aadharNo')
        //console.log(data);
        if (data != '') {
            this.dataFromDigiCame = true
            // this.dataFromDigiCame = true

            // this.kycLoader = false
            // this.KycFetchDisp = true
            this.name = sessionStorage.getItem('name')
            this.dob = sessionStorage.getItem('dob')
            this.aadharNo = sessionStorage.getItem('aadharNo')
            this.address = sessionStorage.getItem('address')
            this.add1 = sessionStorage.getItem('add1')
            this.add2 = sessionStorage.getItem('add2')
            this.add3 = sessionStorage.getItem('add3')
            this.add4 = sessionStorage.getItem('add4')
            this.country = sessionStorage.getItem('country')
            this.district = sessionStorage.getItem('district')
            this.fatherName = sessionStorage.getItem('fatherName')
            this.pinCode = sessionStorage.getItem('pinCode')
            this.state = sessionStorage.getItem('state')
            this.gender = sessionStorage.getItem('gender')
        }
        var ekycstatus = this.$route.query.data;
        //console.log("ekycstatus", ekycstatus);
        if (ekycstatus) {
            ////console.log('ekyc status:', ekycstatus);
            localStorage.setItem("stageis", 8)
            this.$router.push('/submit')
            // this.Slidervalue = 100
            this.ekycStatus = true;
            this.mobile_num = atob(localStorage.getItem('hash'))
            this.digioFile = localStorage.getItem('fileid')
            ////console.log('Disp : ', atob(localStorage.getItem('hash')), this.digioFile)
            var config2 = {
                method: 'post',
                url: api.api_url + '/ekycUpdate?mob=' + this.mobile_num + '&fileid=' + this.digioFile,
                headers: {}
            };
            axios(config2)
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                })
                .catch(function (error) {
                    //console.log(error);
                });
        }
        else {
            // localStorage.removeItem("hash");
            // localStorage.removeItem("fileid");
            // localStorage.removeItem("sesslog");
        }
        this.mainurl = api.api_url;
    },
}
</script>
<style lang="scss">
/*  */

.card-sty1 {
    /* border: 1px solid #F2F2F2 !important; */
    box-shadow: 0px 20px 25px 20px rgba(175, 175, 175, 0.2) !important;
    overflow: hidden;
    border-radius: 30px !important;
    /* margin-left: -1px !important; */
    /* margin-right: 2px !important; */
}

.body-card {
    height: 100vh !important;
    

    /* overflow-y: scroll !important; */
}




::-webkit-scrollbar {
    width: 6px;
    height: 5px;

}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(214, 214, 214, 0);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(233, 232, 232);
    border-radius: 10px;
}

.scrollchnage {
    ::-webkit-scrollbar {
        display: none;
    }

}

// .v-text-field--outlined>.v-input__control>.v-input__slot {
//     align-items: stretch;
//     min-height: 73px;
// }

.scrollhighlight{
    // height: 20vh;
    height: calc(90vh - 160px);

}

// /* Media Query for Mobile Devices */
// @media (max-width: 480px) {
//     body {

//     }
// }

// /* Media Query for low resolution  Tablets, Ipads */
// @media (min-width: 481px) and (max-width: 767px) {
//     body {
//         background-color: yellow;
//     }
// }

// /* Media Query for Tablets Ipads portrait mode */
// @media (min-width: 768px) and (max-width: 1024px) {
//     body {
//         background-color: blue;
//     }
// }

// /* Media Query for Laptops and Desktops */
// @media (min-width: 1025px) and (max-width: 1280px) {
//     body {
//         background-color: green;
//     }
// }

// /* Media Query for Large screens */
// @media (min-width: 1281px) {
//     body {
//         background-color: white;
//     }
// }
</style>